/* eslint-disable no-undefined */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import { IComponentAsProps, Dialog, IconButton, PrimaryButton, TextField, INavButtonProps, mergeStyles, mergeStyleSets, Nav, Stack, StackItem } from '@fluentui/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { Connect, ErrorDialogProvider } from '../components';
import '../styles/globals.css';
import { Icons } from '../utils';
import { GlobalState, state, g_globalDisplayStrs } from '../state';
import { register as registerIcons } from '../utils/icons';
import axios from 'axios';
import { runInAction } from 'mobx';
import { getCookie, setCookie, removeCookie } from 'typescript-cookie';
import 'element-theme-default';
import { isInternet, devConfig, releaseConfig, isDebug, setEnvConfig } from '@yum/myconfig';
import Bus from "../components/eventBus"
import { HttpLogBus } from "@yum/uploadworker"
import crypto from "crypto"
// 引入crypto模块
import { IndexDB, VideoData, ReUploadType } from '@fly/re-upload';
import { getFinishedTestInfo } from '@yum/uploadworker';

HttpLogBus.setMaxListeners(10)
declare global {
    interface Window {
        aegisEntry: any;
    }
}


axios.defaults.withCredentials = true;

registerIcons();


let _disable = false
function NavLink({ link, defaultRender: DefaultRender, ...props }: IComponentAsProps<INavButtonProps>) {
    if (!link) {
        return null;
    }
    let buff = _disable
    if ("/scrcpy" == link.url) {
        buff = false
    }
    return (
        <Link href={link.url} passHref>
            <DefaultRender {...props} disabled={buff} />
        </Link>
    );

}
var m_aegisEntry: any
m_aegisEntry = undefined
let m_first = true
function App({ Component, pageProps }: AppProps) {
    Bus.addListener('SetTabDisable', (msg) => {
        _disable = msg
        setROUTES([
            // {
            //     url: '/',
            //     icon: Icons.Bookmark,
            //     name: 'README',
            // },
            {
                url: '/device-info',
                icon: Icons.Phone,
                name: '设备信息',
            },
            // {
            //     url: '/file-manager',
            //     icon: Icons.Folder,
            //     name: 'File Manager',
            // },
            // {
            //     url: '/framebuffer',
            //     icon: Icons.Camera,
            //     name: 'Screen Capture',
            // },
            // {
            //     url: '/reverse',
            //     icon: Icons.WindowConsole,
            //     name: 'reverse',
            // },
            // {
            //     url: '/shell',
            //     icon: Icons.WindowConsole,
            //     name: 'Interactive Shell',
            // },
            {
                url: '/video-manager',
                icon: Icons.Folder,
                name: '视频续传管理',
            },
            {
                url: '/scrcpy',
                icon: Icons.PhoneLaptop,
                name: '手机/PC投屏',
            },
            // {
            //     url: '/tcpip',
            //     icon: Icons.WifiSettings,
            //     name: 'ADB over WiFi',
            // },
            // {
            //     url: '/install',
            //     icon: Icons.Box,
            //     name: 'Install APK',
            // },
            // {
            //     url: '/logcat',
            //     icon: Icons.BookSearch,
            //     name: 'Logcat',
            // },
            // {
            //     url: '/power',
            //     icon: Icons.Power,
            //     name: 'Power Menu',
            // },
            // {
            //     url: '/bug-report',
            //     icon: Icons.Bug,
            //     name: 'Bug Report',
            // },
            // {
            //     url: '/packet-log',
            //     icon: Icons.TextGrammarError,
            //     name: 'Packet Log',
            // },
        ])

    });





    const classNames = mergeStyleSets({
        'title-container': {
            borderBottom: '1px solid rgb(243, 242, 241)',
        },
        title: {
            padding: '4px 0',
            fontSize: 20,
            textAlign: 'center',
        },
        username: {
            fontSize: 12,
            textAlign: 'right',
        },
        login: {

        },
        button: {
            marginLeft: '100px',
            marginTop: '10px',
            textAlign: 'center',
        },
        'left-column': {
            width: 270,
            paddingRight: 8,
            borderRight: '1px solid rgb(243, 242, 241)',
            overflow: 'auto',
        },
        'right-column': {
            borderLeft: '1px solid rgb(243, 242, 241)',
        },

    });

    const [leftPanelVisible, setLeftPanelVisible] = useState(false);
    const [isLoginDialogOpen, setLoginDialog] = useState(false);
    const [usernameLogin, setUsername] = useState('');
    const [ROUTES, setROUTES] = useState([
        // {
        //     url: '/',
        //     icon: Icons.Bookmark,
        //     name: 'README',
        // },
        {
            url: '/device-info',
            icon: Icons.Phone,
            name: '设备信息',
        },
        // {
        //     url: '/file-manager',
        //     icon: Icons.Folder,
        //     name: 'File Manager',
        // },
        // {
        //     url: '/framebuffer',
        //     icon: Icons.Camera,
        //     name: 'Screen Capture',
        // },
        // {
        //     url: '/reverse',
        //     icon: Icons.WindowConsole,
        //     name: 'reverse',
        // },
        // {
        //     url: '/shell',
        //     icon: Icons.WindowConsole,
        //     name: 'Interactive Shell',
        // },
        {
            url: '/video-manager',
            icon: Icons.Folder,
            name: '视频续传管理',
        },
        {
            url: '/scrcpy',
            icon: Icons.PhoneLaptop,
            name: '手机/PC投屏',
        },
        // {
        //     url: '/tcpip',
        //     icon: Icons.WifiSettings,
        //     name: 'ADB over WiFi',
        // },
        // {
        //     url: '/install',
        //     icon: Icons.Box,
        //     name: 'Install APK',
        // },
        // {
        //     url: '/logcat',
        //     icon: Icons.BookSearch,
        //     name: 'Logcat',
        // },
        // {
        //     url: '/power',
        //     icon: Icons.Power,
        //     name: 'Power Menu',
        // },
        // {
        //     url: '/bug-report',
        //     icon: Icons.Bug,
        //     name: 'Bug Report',
        // },
        // {
        //     url: '/packet-log',
        //     icon: Icons.TextGrammarError,
        //     name: 'Packet Log',
        // },
    ]);

    let errorInfo = '';
    const toggleLeftPanel = useCallback(() => {
        setLeftPanelVisible(value => !value);
    }, []);

    const openLoginDialog = useCallback(() => {
        setLoginDialog(value => !value);
        setUsername('');
        const formdata = new FormData();
        formdata.append('Mysessionid', getCookie('Mysessionid') as string);

        let url = '';
        if (isInternet) {
            url = `${releaseConfig.apiBase}/api/v1/account/logout/`;
        } else {
            url = `${devConfig.apiBase}/api/v1/account/logout/`;
        }

        axios.post(url, formdata).then(res => {
            const r = res.data;

            if (r.code !== 0) { // 登录错误，显示错误信息
                // handleErrorInfo();
                window.aegisEntry.error('logout fail', getCookie('username'));
                alert(r.msg);
            }
            // else {
            //     setCookie('Mysessionid',r.data.Mysessionid,{expires:7,sameSite:'None',domain:'uxlab.qq.com'});
            //     setCookie('username',state.username,{expires:7});
            //     setLoginDialog(value => !value);
            //     setUsername(state.username);
            //     state.LoginState = true;
            //     state.username = '';
            //     state.password = '';
            // }
        });
        window.aegisEntry.infoAll('user logout success', getCookie('username'));
        removeCookie('Mysessionid');
        removeCookie('username');
        window.location.href = '/';
    }, []);

    const handleErrorInfo = useCallback((e, value?: string) => {
        if (value === undefined) {
            return;
        }
        runInAction(() => errorInfo = value);
    }, []);
    const handleUsername = useCallback((e, value?: string) => {
        if (value === undefined) {
            return;
        }
        runInAction(() => state.username = value);
    }, []);
    const handlePassword = useCallback((e, value?: string) => {
        if (value === undefined) {
            return;
        }
        runInAction(() => state.password = value);
    }, []);
    const deletData = useCallback(async (id: any, my_indexedDB: any, m_currentData: any) => {// 这里是一次性通过id，删除掉db的数据和展示的数据
        if (my_indexedDB) {
            await my_indexedDB.DeleteIndex(id);
            let filterds_child_index = m_currentData.findIndex((ele: { id: any; }) => ele.id == id);
            if (filterds_child_index != -1) m_currentData.splice(filterds_child_index, 1);
        }
    }, []);
    useEffect(() => {



        window.aegisEntry.error("用户打开页面");
        console.log("process.env.APP_ENV:", process.env.APP_ENV, window.location)
        //@ts-ignore
        if (typeof window !== 'undefined' && window.indexedDB) {
            let my_indexedDB = IndexDB.GetInstance(ReUploadType.VIDEOBASE_ANDROID_PC, 10, getCookie('username'));
            my_indexedDB.Waite().then(
                async () => {
                    let m_currentData = (await my_indexedDB.GetAllData() as VideoData[]);

                    type my_type = {
                        [key: string]: Array<any>
                    }

                    let getFinishedTestInfo_ofTask: my_type = {}; // //key是task_id,value该task下所有test结果
                    for (let index = 0; index < m_currentData.length; index++) {
                        const element = m_currentData[index];
                        if (!getFinishedTestInfo_ofTask[element.task_id as string]) {
                            //@ts-ignore
                            var getFinishedTestInfo_rt = await getFinishedTestInfo(element.player_id, getCookie("Mysessionid") ? getCookie("Mysessionid") : element.mysessionid, element.task_id);
                            if (getFinishedTestInfo_rt.code == 0) getFinishedTestInfo_ofTask[element.task_id] = getFinishedTestInfo_rt.data.test_info;
                            else {
                                (window as any).aegisEntry.error("本地getFinishedTestInfo失败,videoId:", element.videoId, element);
                                await deletData(element.id, my_indexedDB, m_currentData);// 请求失败直接删除这条数据，因为是cookie失效导致的，这是不可逆的
                            }
                        }
                        //console.log("m_currentData_origin", JSON.parse(JSON.stringify(m_currentData)), getFinishedTestInfo_ofTask, element.task_id, getFinishedTestInfo_ofTask[element.task_id].find(ele => ele.test_id == element.test_id))
                        if (getFinishedTestInfo_ofTask[element.task_id] && Array.isArray(getFinishedTestInfo_ofTask[element.task_id]))// 因为有可能请求失败，为空
                        {
                            let test_res = getFinishedTestInfo_ofTask[element.task_id].find(ele => ele.test_id == element.test_id);
                            if (test_res?.state == 2)// 说明全部上传完了，可以把当这条记录删掉了，但是不要把当前test_id的所有的记录全部删掉
                            {
                                await deletData(element.id, my_indexedDB, m_currentData);
                                index = 0;// 这里重新开始遍历，因为不知道删了哪些
                            }

                        }
                        //先检测是不是上传成功
                    }
                    (window as any).aegisEntry.infoAll("即将跳转到video-manager，_app检测没有上传的分片数：", m_currentData.length);
                    if (m_currentData.length != 0) {
                        if (!window.location.pathname.includes("video-manager")) {
                            window.location.pathname = "/video-manager"
                        }


                    }
                }
            );


        }
        if (typeof window !== 'undefined') {

            window.onbeforeunload = function (e) {
                window.aegisEntry.error("用户关闭页面");
                let ee = window.event || e;
                ee.returnValue = ('确定离开当前页面吗？');

            };

        }
        if (process.env.NODE_ENV == 'development') {
            setEnvConfig(false, true);
        } else if (process.env.NODE_ENV == 'production') {
            setEnvConfig(true, false);
            if (window.location.href.includes('test')) {
                setEnvConfig(false, false);
            }
        }
        console.log(`[${process.env.NODE_ENV}] ${window.location.href} ${isDebug} ${isInternet}`);
        setLeftPanelVisible(innerWidth > 650);
        const thisusername = getCookie('username');
        if (document.location.search.indexOf('auth_token') !== -1) {
            let url = '';
            if (isInternet) {
                url = `${releaseConfig.apiBase}/api/v1/account/login/${document.location.search}&login_type=1`;
            } else {
                url = `${devConfig.apiBase}/api/v1/account/login/${document.location.search}&login_type=1`;
            }
            setCookie('loginType', '1', { expires: 3 });
            axios.get(url).then(res => {
                res = res.data;
                setCookie('Mysessionid', res.data.Mysessionid, { expires: 3 });
                setCookie('username', res.data.username, { expires: 3 });
                setCookie('test', 1);
                setLoginDialog(false);
                setUsername(res.data.username);
                document.location.href = document.location.href.split('?')[0];
            });
        } else {
            if (thisusername !== undefined) {
                setLoginDialog(false);
                setUsername(thisusername);
                const Mysessionid = getCookie('Mysessionid');
                // state.LoginState = true;
                // state.loginType = 2;
                // state.mysessionid = Mysessionid;
            } else {
                setLoginDialog(true);
            }
        }

    }, []);
    useEffect(() => {
        window.onerror = function (message, source, lineno, colno, error) {
            console.log('捕获到异常：', { message, source, lineno, colno, error });
        };

        window.aegisEntry.setConfig({ uin: getCookie('username') });
    });
    async function CheckCaptcha() {


        let final_res = 0
        if (isDebug)//本地测试就跳过验证码
        {
            final_res = 1
        }
        else {
            // @ts-ignore
            var captcha = new TencentCaptcha('2062785411', // 验证码appid
                (res: { ticket: any; }) => {

                    /* res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
                       res（客户端出现异常错误 仍返回可用票据） = {ret: 0, ticket: "String", randstr: "String", 
                                                             errorCode: Number, errorMessage: "String"}
                       res（用户主动关闭验证码）= {ret: 2}
                    */

                    if (res.ticket) {
                        // @ts-ignore
                        let ruazhi = parseInt(new Date().getTime() / 1000 / 60 / 60) + "checkticketojo2062785411";
                        // 规定使用哈希算法中的MD5算法
                        const hash = crypto.createHash('md5');
                        // 可任意多次调用update(),效果相当于多个字符串相加
                        hash.update(ruazhi);
                        const md5 = hash.digest('hex');


                        let url = '';

                        if (isInternet) {
                            url = `${releaseConfig.apiBase}/api/v1/checkticket/?ticket=` + res.ticket + "&randstr=@KFt&key=" + md5;
                        } else {
                            url = `${devConfig.apiBase}/api/v1/checkticket/?ticket=` + res.ticket + "&randstr=@KFt&key=" + md5;
                        }

                        setCookie('loginType', '1', { expires: 3 });
                        axios.get(url)
                            .then(function (response: any) {
                                if (response.data && response.data.code == 0)
                                    final_res = 1
                                else
                                    final_res = -1
                            })
                            .catch(function (error) {
                                final_res = -1
                                console.error(error);
                            });
                        // ticket长度预留空间请不要小于1024字节
                        // 上传票据 可根据errorCode和errorMessage做特殊处理或统计
                    }
                    else
                        final_res = -1
                },  // 验证码回调函数
                {  // 验证码options配置参数
                    ready: function () { },
                    needFeedBack: true
                }
            );
            // 显示验证码
            captcha.show();
        }

        return await new Promise((resolve) => {

            let id = setInterval(() => {
                if (final_res != 0) {
                    clearInterval(id)
                    resolve(final_res)
                }

            }, 1000)

        })
    }
    function jumpToTapd() {
        if (isInternet) {
            window.location.href = releaseConfig.tapdLoginUrl

        } else {
            window.location.href = devConfig.tapdLoginUrl
        }

    }
    function processLogin(Username: string, Password: string) {
        CheckCaptcha().then((e) => {
            if (e != 1) {
                alert("验证码出错！请重新登录")
                return;
            }
            if (Username === '' || Password === '') {
                errorInfo = 'Please input the username and password';
                alert(errorInfo);
                return;
            }

            const dataObj = {
                username: Username,
                login_type: '2',
                password: GlobalState.encryptDES(Password, GlobalState.secret).toString(),
            };
            const formdata = new FormData();
            formdata.append('username', dataObj.username);
            formdata.append('login_type', dataObj.login_type);
            formdata.append('password', dataObj.password);

            let url = '';
            if (isInternet) {
                url = `${releaseConfig.apiBase}/api/v1/account/login/`;
            } else {
                url = `${devConfig.apiBase}/api/v1/account/login/`;
            }

            axios.post(url, formdata).then(res => {
                const r = res.data;
                if (r.code !== 0) {
                    alert(r.msg);
                } else {
                    console.log('login response =', r);
                    setCookie('Mysessionid', r.data.Mysessionid, { expires: 3 });
                    setCookie('username', state.username, { expires: 3 });
                    setCookie('loginType', 2, { expires: 3 });
                    setLoginDialog(value => !value);
                    setUsername(state.username);
                    state.LoginState = true;
                    state.loginType = r.data.role;
                    state.mysessionid = r.data.Mysessionid;
                    window.aegisEntry.setConfig({ uin: state.username });
                    window.aegisEntry.infoAll('user login success', state.username);
                    location.reload();
                }
            });

            console.log(state);
        }).catch((e) => {
            console.error(111, e)

        });

    }

    const router = useRouter();

    return (
        <ErrorDialogProvider>

            <Head>
                <link rel="manifest" href="/manifest.json" />
                <script crossOrigin="anonymous" src="https://tam.cdn-go.cn/aegis-sdk/latest/aegis.min.js"></script>

            </Head>

            <script src="https://video.sdk.qcloudecdn.com/web/TXLivePusher-2.0.4.min.js" ></script>
            <script src="https://www.webrtc-experiment.com/MultiStreamsMixer.js"></script>
            <script src="https://t.captcha.qq.com/TCaptcha.js"></script>
            <script dangerouslySetInnerHTML={{
                __html: "window.aegisEntry = new Aegis({id:'ELrFIUQDKROPNcPwZV',reportApispeed: true,websocketHack:true,api: {apiDetail: true}});"
            }}></script>
            <Stack verticalFill>
                <Stack className={classNames['title-container']} horizontal verticalAlign="center">
                    <IconButton
                        checked={leftPanelVisible}
                        title="Toggle Menu"
                        iconProps={{ iconName: Icons.Navigation }}
                        onClick={toggleLeftPanel}
                    />

                    <StackItem grow>
                        <div className={classNames.title}> {g_globalDisplayStrs.website_title}</div>
                    </StackItem>
                    <StackItem>
                        <div className={classNames.username}> Welcome! {usernameLogin}</div>
                    </StackItem>
                    <PrimaryButton
                        onClick={openLoginDialog}
                    >
                        Logout
                    </PrimaryButton>

                    {/* <IconButton
                        iconProps={{ iconName: 'PersonFeedback' }}
                        title="Feedback"
                        as="a"
                        href="https://github.com/yume-chan/ya-webadb/issues/new"
                        target="_blank"
                    /> */}
                </Stack>

                <Stack grow horizontal verticalFill disableShrink styles={{ root: { minHeight: 0, overflow: 'hidden', lineHeight: '1.5' } }}>
                    <StackItem >
                        <Connect />

                        <Nav
                            groups={[{
                                links: ROUTES.map(route => ({
                                    ...route,
                                    key: route.url,
                                })),
                            }]}
                            linkAs={NavLink}
                            selectedKey={router.pathname}

                        />
                    </StackItem>

                    <StackItem grow styles={{ root: { width: 0 } }}>
                        <Component {...pageProps} />
                    </StackItem>
                </Stack>
            </Stack>
            <Dialog
                isOpen={isLoginDialogOpen}
                title="Login"
                isBlocking={true}
                minWidth={400}
            >
                <TextField
                    label="username"
                    onChange={handleUsername}
                >
                </TextField>
                <TextField
                    label="password"
                    type="password"
                    onChange={handlePassword}
                >
                </TextField>
                <div>
                    <PrimaryButton
                        className={classNames['button']}
                        onClick={() => processLogin(state.username, state.password)}
                    >
                        Confirm
                    </PrimaryButton>
                    <span style={{ marginLeft: 10 }}>
                        or
                    </span>

                    <PrimaryButton
                        onClick={() => jumpToTapd()}
                        className={classNames['button']}
                    >
                        Tapd登录
                    </PrimaryButton>
                </div>

            </Dialog>
        </ErrorDialogProvider >
    );
}


HttpLogBus.addListener('SendLog', (msg: any) => {
    console.error("SendLog", msg)
    if (m_aegisEntry)
        m_aegisEntry.error(msg)
}
)
export default App;

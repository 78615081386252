import crypto from 'crypto';
import { isInternet, devConfig, releaseConfig } from '@yum/myconfig';
import { EventEmitter } from 'events';
var ele_m3u8: any = {}
export function SetEleM3u8(input: any) {
    ele_m3u8 = input
    console.log("ele_m3u81", ele_m3u8)
}



const svpHost = "50091.gzc.svp.tencent-cloud.com";
const svpBizId = 50091;
const svpFileId = "1";
const svpFileType = "webm";
const svpCateId = 1;
const svpIsPrefix = false;
// const svpThreadNum = 10;
const svpTimeOut = 60 * 60;
// const httpTimeOut = 20;
const svpSkipAudit = true;
// const maxUploadVideoCount = 1;
const svpAuthKey = "K9mZB0AOGI2I";
// const svpTransFormatIdArray = ["104000", "103000", "101000", "100000"];
const initUploadUrl = "https://" + svpHost + "/initupload";
const uploadUrl = "https://" + svpHost; // uploadpart?filename=" + fileName + "&partnum=" + to_string(partNum + 1) + "&ukey=" + uKey;"
const finishUploadUrl = "https://" + svpHost + "/finishupload";
const getPlayInfoUrl = "https://" + svpHost + "/getplayinfo";
const getListPartsUrl = "https://" + svpHost + "/listparts";

const blockSize = 1024 * 1024;
const maxBlockNum = 10000;
const maxRetryTimes = 3;
const delayOfRetry = 300;
async function delay(params: number) {
    return await new Promise(
        resolve => {
            setTimeout(() => {
                resolve(1);
            }, params);
        }
    )

}

async function Retry(fuc: () => any) {
    let response
    for (let i = 0; i <= maxRetryTimes; i++) {
        response = await fuc();
        let log = "Retry:" + i + "   response:" + JSON.stringify(response) + fuc;

        console.log("SendLog", HttpLogBus)

        if (!response || !response.ok) {
            console.error(log)
            HttpLogBus.emit("SendLog", log)
            await delay(delayOfRetry);

            continue;
        }
        let buff = response.clone()//因为这里要使用response.json 获取返回数据判断是否返回成功，该方法调用一次后就不能再调用，考虑到兼容性，在这里克隆一个调用
        var result = (await buff.json());
        log = "Retry:" + i + "   result:" + JSON.stringify(result) + fuc;
        if (!result || result.code != 0) {
            console.error(log)
            HttpLogBus.emit("SendLog", log)
            await delay(delayOfRetry);
            continue;
        }
        console.log(222, result)
        break;
    }
    return response;
}
function getAuthorization(url: string, svpAuthKey: string, timeout: number): string {
    const timestamp: number = Date.parse(new Date().toString()) / 1000;   //  秒
    let authInfo = url + timestamp.toString() + (timestamp + timeout).toString() + svpAuthKey;
    const sha1Handler = crypto.createHash('md5');

    let authorizationInfo = sha1Handler.update(authInfo).digest('hex').toString() + "_" + timestamp.toString() + "_" + timeout.toString();
    return authorizationInfo;
};
//上传文件 fileType:文件类型，”heatbeat"/"keyboard"
async function uploadFile(req_id: string, task_id: string, player_id: string, test_id: string, Mysessionid: string, myfile: File, fileType: string) {

    let url = "";
    if (isInternet) {
        url = releaseConfig.apiBase + "/api/v1/uploadeventdata/" + fileType + "/";
    } else {
        url = devConfig.apiBase + "/api/v1/uploadeventdata/" + fileType + "/";
    }
    const formData = new FormData();

    formData.append('req_id', req_id);
    formData.append('task_id', task_id);
    formData.append('player_id', player_id);
    formData.append('test_id', test_id);
    formData.append('Mysessionid', Mysessionid);
    formData.append('filename', myfile);
    let response = await Retry(async () => {
        return await fetch(url, {
            method: 'POST',
            body: formData,

        });
    });
    let result = (await response.json());

    if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
    }
    return result;


}


//上传情绪数据
async function uploadEmotionDataCore(req_id: string, task_id: string, player_id: string, test_id: string, Mysessionid: string, myfile: File) {
    let url = "";
    if (isInternet) {
        url = releaseConfig.apiBase + "/api/v1/uploademotiondata/";
    } else {
        url = devConfig.apiBase + "/api/v1/uploademotiondata/";
    }
    const formData = new FormData();

    formData.append('req_id', req_id);
    formData.append('task_id', task_id);
    formData.append('player_id', player_id);
    formData.append('test_id', test_id);
    formData.append('Mysessionid', Mysessionid);
    formData.append('filename', myfile);
    let response = await Retry(async () => {
        return await fetch(url, {
            method: 'POST',
            body: formData,

        });
    });
    return response;
}

//上传热力图数据
async function uploadHeatMapDataCore(req_id: string, task_id: string, player_id: string, test_id: string, Mysessionid: string, myfile: File) {
    let url = "";
    if (isInternet) {
        url = releaseConfig.apiBase + "/api/v1/save_hot_map_data/";
    } else {
        url = devConfig.apiBase + "/api/v1/save_hot_map_data/";
    }
    const formData = new FormData();

    formData.append('req_id', req_id);
    formData.append('task_id', task_id);
    formData.append('player_id', player_id);
    formData.append('test_id', test_id);
    formData.append('Mysessionid', Mysessionid);
    formData.append('filename', myfile);

    let response = await Retry(async () => {
        return await fetch(url, {
            method: 'POST',
            body: formData,

        });
    });

    return response;
}

/**
 * 上传文件前initUpload
*/
async function initUpload(fileSize: number) {
    try {

        let authinfo = getAuthorization("/initupload", svpAuthKey, svpTimeOut);
        console.log(authinfo);

        let response = await Retry(async () => {
            return await fetch(initUploadUrl, {
                method: 'POST',
                body: JSON.stringify({
                    bizId: svpBizId,
                    cateId: svpCateId,
                    fileUpInfo: {
                        fileId: svpFileId,
                        fileType: svpFileType,
                        fileSize: fileSize,
                        fileSha: '',
                        fileidAsPrefix: svpIsPrefix,
                        //hls: 1
                    }
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authinfo,
                    Accept: 'application/json',
                },
            });
        });


        if (!response.ok) {
            //throw new Error(`Error! status: ${response.status}`);
        }
        let result = (await response.json());

        result["code_flyflychen"] = 0;
        console.log('result is: ', JSON.stringify(result, null, 4));
        return result;
    } catch (error) {
        if (error instanceof Error) {
            console.error('error message: ', error.message);
            return error.message;
        } else {
            console.error('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}

/**
 * 异步上传
 */


/**
 *
 * @param ukey
 * @param filename
 * @param fileSize
 * @param blockNum
 * @param curBlockSize
 * @param blob
 * @param block
 * @returns
 */
async function uploadBlocksByConcurrent2(ukey: string, filename: string, blob: Blob, seqNum: number) {
    try {
        var retHashDigestList = [];
        // var uploadQueryUrl = "/uploadpart?filename=" + filename + "&partnum=" + (i + 1).toString() + "&ukey=" + ukey;
        var uploadQueryUrl = "/uploadpart?filename=" + filename + "&partnum=" + (seqNum).toString() + "&ukey=" + ukey;
        let authinfo = getAuthorization(uploadQueryUrl, svpAuthKey, svpTimeOut);

        const response = await Retry(async () => {
            return await fetch(uploadUrl + uploadQueryUrl, {
                method: 'POST',
                body: blob,
                headers: {
                    'Authorization': authinfo,
                    'Accept-Encoding': 'gzip,deflate',
                    "Accept": "*/*"
                },
            });
        })


        if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`);
        }

        let result = (await response.json());
        await Timeout();
        if (result['partSha'] !== undefined) {
            retHashDigestList.push({ "partNum": seqNum, "partSha": result['partSha'] });

        }


    } catch (error) {
        if (error instanceof Error) {
            console.log('error message: ', error.message);
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    } finally {
        return retHashDigestList;
    }
}
async function Timeout(time = 500) {
    return new Promise(function (resolve1) {
        setTimeout(resolve1, time);
    });
}
/**
 * 更新结束上传
 */
async function finishUpload(ukey: string, filename: string, retShaList: any) {
    try {
        let authinfo = getAuthorization("/finishupload", svpAuthKey, svpTimeOut);

        const response = await Retry(async () => {
            return await fetch(finishUploadUrl, {
                method: 'POST',
                body: JSON.stringify({
                    bizId: svpBizId,
                    fileName: filename,
                    uKey: ukey,
                    finishParts: retShaList,
                    skipAudit: svpSkipAudit,
                    // vHls: {//vHls貌似有问题，懒得跟他们调试了，就这样吧
                    //     slowStart: 1,
                    //     segmentTime: 12,
                    //     slice: -1
                    // },
                    // transInfo: {//现在不用他的转码了
                    //     "transcode": [
                    //         {
                    //             "formatId": 30,//这个东西是跟svp后端沟通后得到了，使用这个码，svp服务器会直接对上传的视频转码
                    //         }]
                    // }
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authinfo,
                    Accept: 'application/json',
                },
            });

        })
        if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`);
        }

        let result = (await response.json());
        if (ele_m3u8["fileName"] && ele_m3u8["uKey"]) {
            console.log('finishUpload', authinfo, "ele_m3u8", ele_m3u8);
            await delay(1000)
            try {
                await Retry(async () => {

                    return await fetch(finishUploadUrl, {
                        method: 'POST',
                        body: JSON.stringify({
                            bizId: svpBizId,
                            fileName: ele_m3u8["fileName"],
                            uKey: ele_m3u8["uKey"],
                            vHls: {
                                slowStart: 1,
                                segmentTime: 12,
                                slice: 1
                            },
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': authinfo,
                            Accept: 'application/json',
                        },
                    });


                })
            }
            catch {
                return {}
            }
        }


        // console.log('result is: ', JSON.stringify(result, null, 4));
        result["code_flyflychen"] = 0;
        await Timeout();
        return result;
    } catch (error) {
        if (error instanceof Error) {
            console.log('error message: ', error.message);
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}
async function isVideoContinue(filename: string | undefined, ukey: string | undefined) {
    var buff: any
    for (let i = 0; i <= maxRetryTimes; i++) {
        buff = await listParts(filename, ukey)
        if (buff && buff.code == 0) {
            let finishParts = buff.finishParts
            finishParts.sort(function (a: { partNum: any; }, b: { partNum: any; }) {
                return Number(a.partNum) - Number(b.partNum);
            })
            if (finishParts[0] && Number.isFinite(finishParts[0].partNum)) {
                if (finishParts[0].partNum !== 1)//第一个序号要是1
                {
                    if (i == maxRetryTimes - 1)
                        return { res: false }
                }

                for (let index = 0; index < finishParts.length; index++) {
                    const element = finishParts[index];
                    if (index + 1 < finishParts.length && element.partNum + 1 != finishParts[index + 1].partNum) {
                        if (i == maxRetryTimes - 1)
                            return { res: false }
                        break;
                    }
                    if (index == finishParts.length - 1)
                        return { res: true }
                }
            }

        }
        await delay(1000 * (i + 1))
    }

    return { res: true, listParts_rt: buff }//这里说明请求异常，大概率是svp服务器的问题，就当做是连续的。
}

async function checkTime(mysessionid: any, delay = 60) {

    try {
        let url = "";
        if (isInternet) {
            url = releaseConfig.apiBase + "/api/v1/checktime/";
        } else {
            url = devConfig.apiBase + "/api/v1/checktime/";
        }
        var timestamp = Math.floor(new Date().getTime() / 1000)

        url += "?Mysessionid=" + mysessionid + '&ctime=' + String(timestamp) + '&delay=' + String(delay)

        const response = await fetch(url, {
            method: 'GET',

        });


        if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`);
        }

        const result = (await response.json());
        if (result.code == 0 && result.data == true) {
            return true
        }
        else return false
    } catch (error) {
        if (error instanceof Error) {
            console.log('checkTime error message: ', error.message);
            return true;
        } else {
            console.log('checkTime unexpected error: ', error);
            return true;
        }
    }
}
/**
 * 测试调试时使用，观察svp的视频链接
 * @param videoid
 * @returns
 */
async function listParts(filename: string | undefined, ukey: string | undefined) {
    try {
        // 临时获取播放链
        var mygetListParts = getListPartsUrl + "?filename=" + filename + "&ukey=" + ukey;
        let authinfo1 = getAuthorization("/listparts" + "?filename=" + filename + "&ukey=" + ukey, svpAuthKey, svpTimeOut);
        console.log(authinfo1);

        const response1 = await fetch(mygetListParts, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authinfo1,
                Accept: 'application/json',
            },
        });


        if (!response1.ok) {
            throw new Error(`Error! status: ${response1.status}`);
        }

        const result1 = (await response1.json());
        return result1;
    } catch (error) {
        if (error instanceof Error) {
            console.log('error message: ', error.message);
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}

/**
 * 测试调试时使用，观察svp的视频链接
 * @param videoid
 * @returns
 */
async function getPlayInfo(videoid: string) {//这个不用重试
    try {
        // 临时获取播放链
        var getplayinfoUrl = getPlayInfoUrl + "?videoid=" + videoid;
        let authinfo1 = getAuthorization("/getplayinfo?videoid=" + videoid, svpAuthKey, svpTimeOut);


        const response1 = await fetch(getplayinfoUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authinfo1,
                Accept: 'application/json',
            },
        });

        if (!response1.ok) {
            throw new Error(`Error! status: ${response1.status}`);
        }

        let result1 = (await response1.json());
        result1["code_flyflychen"] = 0;
        await Timeout();
        return result1;
    } catch (error) {
        if (error instanceof Error) {
            console.log('error message: ', error.message);
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}

/**
 * 上传视频测试结果
 * @returns
 */
async function uploadVideoData(playerid: string, mysessionid: string, taskid: number, testid: number, reqid: number, video_type_index: number, video_sub_id: string) {
    try {
        let urlparam = "";
        if (isInternet) {
            urlparam = releaseConfig.apiBase + "/api/v1/uploadvideodata/";
        } else {
            urlparam = devConfig.apiBase + "/api/v1/uploadvideodata/";
        }

        const response = await Retry(async () => {
            return await fetch(urlparam, {
                method: 'POST',
                body: JSON.stringify({
                    "player_id": playerid,
                    "Mysessionid": mysessionid,
                    "task_id": taskid,
                    "test_id": testid,
                    "noCookie": 1,
                    "req_id": reqid,
                    "video_type_index": video_type_index, // android/windows视频1， 脸部视频0，手部视频2
                    "video_sub_id": video_sub_id,     // "2022-06-10 12:01:00",
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
        })


        if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`);
        }

        let result = (await response.json());
        result["code_flyflychen"] = 0;
        await Timeout();
        return result;
    } catch (error) {
        if (error instanceof Error) {
            console.log('error message: ', error.message);
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}

/**
 * 上传最后测试总结果
 * @returns
 */
async function uploadFinalTestRecord(playerid: string, mysessionid: string, taskid: number, testid: number, reqid: number, starttime: string, endtime: string, duration_ms: number,
    handVideo: Boolean, faceVideo: Boolean, androidVideo: Boolean, winVideo: Boolean, emotionDataLength: number, heartbeat_length: any | undefined = undefined, heatmap_length: number | undefined = undefined) {
    try {
        let url = "";
        console.log('uploadFinalTestRecord', isInternet);
        if (isInternet) {
            url = releaseConfig.apiBase + "/api/v1/updatetestinfo/";
        } else {
            url = devConfig.apiBase + "/api/v1/updatetestinfo/";
        }
        console.log("emotionDataLength", emotionDataLength)
        const response = await Retry(async () => {
            return await fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    "player_id": playerid,
                    "Mysessionid": mysessionid,
                    "task_id": taskid,
                    "test_id": testid,
                    "noCookie": 1,
                    "req_id": reqid,
                    "start_time": starttime ? starttime : "1995-07-20 12:00:00", // "2022-06-10 12:00:00",
                    "end_time": endtime ? endtime : "1995-07-20 13:00:00",     // "2022-06-10 12:01:00",
                    "test_duration": duration_ms ? duration_ms / 1000 : 1,//如果不存在就随便写一个
                    "is_landscape": 1,
                    "resolution_w": 720,
                    "resolution_h": 1080,
                    "has_face_vid": faceVideo ? 1 : 0,      // 脸部视频
                    "has_heatmap_vid": handVideo ? 1 : 0,   // 手部视频
                    "has_game_vid": (androidVideo || winVideo) ? 1 : 0,     // 手机和windows视频同时计数，当前只有android，填1
                    "heatmap_length": heatmap_length ? heatmap_length : -1,
                    "emoji_length": emotionDataLength ? emotionDataLength : -1,
                    "platform_type": androidVideo ? 6 : 7,   // 0-android, 1-ios, 2-windows, 3-switch, 4-ps4, 5-xbox, 6-web_android,  7-web_pc
                    "heartbeat_length": heartbeat_length ? heartbeat_length : -1
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
        })


        if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`);
        }

        const result = (await response.json());
        return result;
    } catch (error) {
        if (error instanceof Error) {
            console.log('error message: ', error.message);
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}
/**
 * 获取最终上传结果状态，是否完全结束
 * @returns
 */
async function getFinishedTestInfo(playerid: string, mysessionid: string, taskid: number) {
    let url: RequestInfo | URL
    if (isInternet) {
        url = releaseConfig.apiBase + "/api/v1/playertasktestid/getfinishedtestinfo/";
    } else {
        url = devConfig.apiBase + "/api/v1/playertasktestid/getfinishedtestinfo/";
    }
    let response = await Retry(async () => {
        return await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                "player_id": playerid,
                "Mysessionid": mysessionid,
                "task_id": taskid,

            }),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
    })
    if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
    }

    const result = (await response.json());
    return result;
}

const HttpLogBus = new EventEmitter();
export {
    HttpLogBus, checkTime, listParts, isVideoContinue, uploadBlocksByConcurrent2, uploadEmotionDataCore, uploadHeatMapDataCore, uploadFile, initUpload, finishUpload,
    getPlayInfo, uploadVideoData, uploadFinalTestRecord, getFinishedTestInfo, maxBlockNum, blockSize
}
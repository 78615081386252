import { initUpload, uploadBlocksByConcurrent2, getPlayInfo, finishUpload, uploadVideoData, maxBlockNum, blockSize, uploadEmotionDataCore, uploadHeatMapDataCore, uploadFile, SetEleM3u8 } from "./uploader.js";
import { testData } from "@yum/myconfig";
import * as ebml from "ts-ebml";
import { uxlab } from "@yum/protobuf";
import fixWebmDuration from "fix-webm-duration";

// declare var self: DedicatedWorkerGlobalScope;
// export {};
export interface IWorkerEchoData {
    result: boolean;
    emotionLength?: number;
    heatmapLength?: number;
    type?: "uploadInitialInfo" | "uploadresult" | "finishUpload";
    testKey?: string;
    sha?: string;
    uKey?: string;
    fileName?: string;
    videoId?: string;
    seqNum?: number;
}

export interface IWorkerPostData {
    type: 'transfer' | 'close' | 'initial' | 'finish',
    data?: any[];
    testData_?: testData;
    duration?: number;
    video_type_index?: number;  // android/windows视频1， 脸部视频0，手部视频2
    isLastSlice?: boolean;      // 是否是最后一个分片
    uKey?: string;
    fileName?: string;
    ShaList?: any[];
    videoId?: string;
    seqNum?: number;
    video_type?: string;
}


function readAsArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        reader.onloadend = () => { resolve(<ArrayBuffer>reader.result); };
        reader.onerror = (ev) => { reject(ev); };
    });
}

/**
 * 获取svp上传初始信息
 */
async function getSVPInitialInfo(startRecord_type: string) {//这个是需要把消息发给对应的视频class，主要有两个，一个是web-decod里面的手机视频，一个是uxlabcamera的摄像头视频
    let svpResult = await initUpload(0);
    let ukey: any, fileName: any
    if (Array.isArray(svpResult.fileList)) {
        let ele_webm = svpResult.fileList.find((element: { fileName: string | string[]; }) => element.fileName.indexOf("m3u8") == -1)
        SetEleM3u8(svpResult.fileList.find((element: { fileName: string | string[]; }) => element.fileName.indexOf("m3u8") != -1))
        if (ele_webm) {
            ukey = ele_webm["uKey"]
            fileName = ele_webm["fileName"]
        }
        else {
            ukey = svpResult[0]["uKey"]
            fileName = svpResult[0]["fileName"]
        }
    }
    else {
        ukey = svpResult["uKey"]
        fileName = svpResult["fileName"]
    }

    postMessage({
        type: startRecord_type,
        result: true,
        uKey: ukey,
        fileName: fileName,
        videoId: svpResult["videoId"]
    } as unknown as IWorkerEchoData);
    if (svpResult.code_flyflychen == 0)
        svpResult.code = 0
    return svpResult;
}

async function uploadSVP(chunks: any[] | undefined, testData: testData | undefined, duration: number | undefined, video_type_index: number | undefined) {
    // 方案1
    //这个东西弃用了，没有删掉是因为删了之后，会导致import crypto from 'crypto';找不到包的问题
    const ebmldecoder = new ebml.Decoder();
    const ebmlreader = new ebml.Reader();
    ebmlreader.logging = false;
    ebmlreader.drop_default_duration = true;
    const blob: Blob = new Blob(chunks, { type: "video/webm;codecs=H264" });
    const webMBuf = await readAsArrayBuffer(blob);
    const elms = ebmldecoder.decode(webMBuf);
    elms.forEach((elm) => { ebmlreader.read(elm); });

}

/**
 * 上传1个分片数据到svp
 * @param chunks
 * @param testData
 * @param duration
 * @param video_type_index
 */
async function uploadSVP2(chunks: any[] | undefined, testData: testData | undefined, duration: number | undefined,
    video_type_index: number | undefined, fileName: string | undefined, uKey: string | undefined, seqNum: number | undefined, videoId: string | undefined, send_initResult: boolean = true, send_uploadresult: boolean = true) {
    // 方案1
    let refinedWebM: Blob = new Blob(chunks, { type: "video/webm;codecs=H264" });
    console.log("时间：", duration, "seqNum:", seqNum)
    if (duration && duration > 0)
        refinedWebM = await ((fixWebmDuration as any)(refinedWebM, duration, { logger: false }));
    if (seqNum != 1)
        chunks = [];  //  马上释放

    // 计算分块数目,计算分块其实没有用，已经废弃，为了不影响其他部分，暂时保留
    var fileSize = refinedWebM.size;
    var blockNum = Math.ceil(fileSize * 1.0 / blockSize);
    var currBlockSize = blockSize;
    while (blockNum > maxBlockNum) {
        currBlockSize += currBlockSize;
        blockNum = Math.ceil(fileSize * 1.0 / currBlockSize);
    }

    if (fileName != null && fileName != undefined
        && uKey != null && uKey != undefined) {
        // 2. 调用svp接口：upload

        var retShaList = await uploadBlocksByConcurrent2(uKey, fileName, refinedWebM, seqNum as number);
        if (send_initResult)//原本这里发送消息才会保存到本地db，但是问题在于这个跟上传时同步的，如果上传速度低于录制速度，结束的时候，就会导致没有上传的分片未保存
            //只有发送失败才保存,第一个也必须保存
            if (seqNum == 1 || retShaList?.length == 0)
                postMessage({
                    "initResult": { videoId, uKey, fileName, "seqNum": seqNum as number }, "refinedWebM": refinedWebM, fileSize, blockNum, currBlockSize, video_type_index
                } as unknown as IWorkerEchoData);
        let tempTestData = (testData as testData);
        // console.log(JSON.stringify(tempTestData));
        let tempKey = `${tempTestData.taskid}_${tempTestData.testid}_${tempTestData.username}_${tempTestData.reqid}`;
        if (send_uploadresult)
            postMessage({
                type: 'uploadresult',
                testKey: tempKey,
                sha: retShaList,
                seqNum: seqNum,
                result: true
            } as IWorkerEchoData);
        return retShaList;
    } else {

        return { fileName, uKey };
    }
}

async function finishUpload2(uKey: string | undefined, fileName: string | undefined, ShaList: any[] | undefined, videoId: string | undefined, video_type_index: number | undefined, testData: testData | undefined) {
    // 3. 调用svp接口：finishUpload

    let finishUpload_rt = await finishUpload(uKey as string, fileName as string, ShaList as any[]);

    if (finishUpload_rt.code == undefined) {
        postMessage({
            type: 'finishUpload',
            result: false
        } as IWorkerEchoData);
        return
    }
    // 4.临时调用svp接口，查询播放链，仅调试用
    let getPlayInfo_rt = await getPlayInfo(videoId as string);


    // 5. 上报视频vid给游戏性后端
    let uploadVideoData_rt = await uploadVideoData((testData as testData).username, (testData as testData).mysessionid, (testData as testData).taskid, (testData as testData).testid, (testData as testData).reqid, video_type_index as number, videoId as string);

    // 发送消息给main
    postMessage({
        type: 'finishUpload',
        result: true
    } as IWorkerEchoData);
    let code = -1;
    if (finishUpload_rt.code_flyflychen == 0 && getPlayInfo_rt.code_flyflychen == 0 && uploadVideoData_rt.code_flyflychen == 0)
        code = 0
    return { code, finishUpload_rt, getPlayInfo_rt, uploadVideoData_rt }
}

/**
 * 上传表情数据
 * @param chunks
 * @param testData
 * @param duration
 */
async function uploadEmotionData(chunks: any[] | undefined, testData: testData | undefined, duration: number | undefined) {
    // console.log('yumzheng: uploadEmotionData');
    // console.log(chunks);
    // console.log(testData);
    // console.log(duration);

    let emojis = new uxlab.Emojis();
    chunks?.forEach((chunk) => {
        // console.log(JSON.stringify(chunk));
        // console.log(chunk.timestamp, chunk.emotion.anger, chunk.emotion.contempt, chunk.emotion.disgust,
        //     chunk.emotion.engagement, chunk.emotion.fear, chunk.emotion.joy, chunk.emotion.sadness, chunk.emotion.surprise,  chunk.emotion.valence);
        let oneemoji = new uxlab.Emoji({
            relativeTime: Math.floor(chunk.timestamp),
            /** Emoji faceid */
            faceid: 0,
            /** Emoji anger */
            anger: Math.floor(chunk.emotion.anger),
            /** Emoji contemt */
            contemt: Math.floor(chunk.emotion.contempt),

            /** Emoji disgust */
            disgust: Math.floor(chunk.emotion.disgust),

            /** Emoji engagement */
            engagement: Math.floor(chunk.emotion.engagement),

            /** Emoji fear */
            fear: Math.floor(chunk.emotion.fear),

            /** Emoji joy */
            joy: Math.floor(chunk.emotion.joy),

            /** Emoji sadness */
            sadness: Math.floor(chunk.emotion.sadness),

            /** Emoji surprise */
            surprise: Math.floor(chunk.emotion.surprise),

            /** Emoji valence */
            valence: Math.floor(chunk.emotion.valence),

            /** Emoji brightness */
            brightness: 0//chunk.brightness
        });
        emojis.emoji.push(oneemoji);
    })

    let pack = uxlab.Emojis.encode(emojis).finish()
    let myfile = new File([pack.buffer], "name",)//这里编码后pack是uint8array，每个数组内装的是uint，pack.buffer才是二进制arraybuff

    // 上传到uxlab后端
    let response = await uploadEmotionDataCore(testData?.reqid.toString() as string,
        testData?.taskid.toString() as string,
        testData?.username as string,
        testData?.testid.toString() as string,
        testData?.mysessionid as string,
        myfile);

    // console.log('yumzheng:uploadEmotionData 2');

    postMessage({
        result: true,
        emotionLength: emojis.emoji.length
    } as IWorkerEchoData);
    if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
    }

    const result = (await response.json());
    return result;
}

/**
 * 上传热力图数据
 * @param chunks
 * @param testData
 * @param duration
 */
async function uploadHeatMapData(chunks: any[] | undefined, testData: testData | undefined, duration: number | undefined) {
    // console.log('jennachen: uploadHeatMapData');
    // console.log(chunks);
    // console.log(testData);
    // console.log(duration);

    chunks?.sort(function (a: { relativeTime: any; }, b: { relativeTime: any; }) {
        return Number(a.relativeTime) - Number(b.relativeTime)
    })
    let motions = new uxlab.Motions();
    chunks?.forEach((chunk) => {

        let onemotion = new uxlab.Motion({

            /** Motion motionId. */
            motionId: chunk.motionId,

            /** Motion motionType. */
            motionType: chunk.motionType,

            /** Motion x. */
            x: chunk.x,

            /** Motion y. */
            y: chunk.y,

            /** Motion relativeTime. */
            relativeTime: chunk.relativeTime,

            /** Motion add. */
            add: chunk.add
        });
        motions.motion.push(onemotion);
    })

    let pack = uxlab.Motions.encode(motions).finish()
    let myfile = new File([pack.buffer], "filename",)//这里编码后pack是uint8array，每个数组内装的是uint，pack.buffer才是二进制arraybuff

    // 上传到uxlab后端
    let response = await uploadHeatMapDataCore(testData?.reqid.toString() as string,
        testData?.taskid.toString() as string,
        testData?.username as string,
        testData?.testid.toString() as string,
        testData?.mysessionid as string,
        myfile);

    console.log('jennachen:uploadHeatMapData 2');
    console.log(JSON.stringify({
        result: true,
        heatmapLength: motions.motion.length
    }));

    postMessage({
        result: true,
        heatmapLength: motions.motion.length
    } as IWorkerEchoData);
    if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
    }

    const result = (await response.json());
    return result;
}


//键盘数据
async function uploadKeyboard(array: any[] | undefined, testData: testData | undefined) {

    let packetKeyboard = new uxlab.KeyBoardEvents()
    if (array != undefined) {
        for (var data of array) {
            let keyboardEvent = new uxlab.KeyBoardEvent({
                relativeTime: data.relativeTime,
                action: data.action,
                value: data.value
            })
            packetKeyboard.keyEvent.push(keyboardEvent)
        }
        console.log("packetKeyboard")
        console.log(packetKeyboard)
        let pack = uxlab.KeyBoardEvents.encode(packetKeyboard).finish()
        let myfile = new File([pack.buffer], "name",)//这里编码后pack是uint8array，每个数组内装的是uint，pack.buffer才是二进制arraybuff


        await uploadFile(testData?.reqid.toString() as string,
            testData?.taskid.toString() as string,
            testData?.username as string,
            testData?.testid.toString() as string,
            testData?.mysessionid as string,
            myfile,
            "keyevent");

        postMessage({
            result: true,
            emotionLength: packetKeyboard.keyEvent.length
        } as IWorkerEchoData);

    }


}

//鼠标数据
async function uploadMouse(array: any[] | undefined, testData: testData | undefined) {

    let packetMouse = new uxlab.MouseEvents()

    if (array != undefined) {
        for (var data of array) {
            let mouseEvent = new uxlab.MouseEvent({
                relativeTime: data.relativeTime,
                x: data.x,
                y: data.y,
                action: data.action
            })
            packetMouse.mouseEvent.push(mouseEvent);
        }

        console.log("mouseData")
        console.log(packetMouse)

        let pack = uxlab.MouseEvents.encode(packetMouse).finish()
        let myfile = new File([pack.buffer], "name",)//这里编码后pack是uint8array，每个数组内装的是uint，pack.buffer才是二进制arraybuff


        await uploadFile(testData?.reqid.toString() as string,
            testData?.taskid.toString() as string,
            testData?.username as string,
            testData?.testid.toString() as string,
            testData?.mysessionid as string,
            myfile,
            "mouseevent")

        postMessage({
            result: true,
            emotionLength: packetMouse.mouseEvent.length
        } as IWorkerEchoData);

    }
}


// export { uploadSVP, uploadEmotionData,uploadMouse,uploadKeyboard};
export { uploadSVP2, finishUpload2, getSVPInitialInfo, uploadSVP, uploadEmotionData, uploadHeatMapData, uploadMouse, uploadKeyboard };


/* eslint-disable block-scoped-var, id-length,
 * no-control-regex, no-magic-numbers,
 * no-prototype-builtins, no-redeclare,
 * no-shadow, no-var, sort-vars */
import * as $protobuf from 'protobufjs/minimal';

// Common aliases
const $Reader = $protobuf.Reader;
const $Writer = $protobuf.Writer;
const $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {});

export const uxlab = ($root.uxlab = (() => {
  /**
   * Namespace uxlab.
   * @exports uxlab
   * @namespace
   */
  const uxlab = {};

  uxlab.Emoji = (function () {
    /**
     * Properties of an Emoji.
     * @memberof uxlab
     * @interface IEmoji
     * @property {number} relativeTime Emoji relativeTime
     * @property {number} faceid Emoji faceid
     * @property {number} anger Emoji anger
     * @property {number} contemt Emoji contemt
     * @property {number} disgust Emoji disgust
     * @property {number} engagement Emoji engagement
     * @property {number} fear Emoji fear
     * @property {number} joy Emoji joy
     * @property {number} sadness Emoji sadness
     * @property {number} surprise Emoji surprise
     * @property {number} valence Emoji valence
     * @property {number} brightness Emoji brightness
     */

    /**
     * Constructs a new Emoji.
     * @memberof uxlab
     * @classdesc Represents an Emoji.
     * @implements IEmoji
     * @constructor
     * @param {uxlab.IEmoji=} [properties] Properties to set
     */
    function Emoji(properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }

    /**
     * Emoji relativeTime.
     * @member {number} relativeTime
     * @memberof uxlab.Emoji
     * @instance
     */
    Emoji.prototype.relativeTime = 0;

    /**
     * Emoji faceid.
     * @member {number} faceid
     * @memberof uxlab.Emoji
     * @instance
     */
    Emoji.prototype.faceid = 0;

    /**
     * Emoji anger.
     * @member {number} anger
     * @memberof uxlab.Emoji
     * @instance
     */
    Emoji.prototype.anger = 0;

    /**
     * Emoji contemt.
     * @member {number} contemt
     * @memberof uxlab.Emoji
     * @instance
     */
    Emoji.prototype.contemt = 0;

    /**
     * Emoji disgust.
     * @member {number} disgust
     * @memberof uxlab.Emoji
     * @instance
     */
    Emoji.prototype.disgust = 0;

    /**
     * Emoji engagement.
     * @member {number} engagement
     * @memberof uxlab.Emoji
     * @instance
     */
    Emoji.prototype.engagement = 0;

    /**
     * Emoji fear.
     * @member {number} fear
     * @memberof uxlab.Emoji
     * @instance
     */
    Emoji.prototype.fear = 0;

    /**
     * Emoji joy.
     * @member {number} joy
     * @memberof uxlab.Emoji
     * @instance
     */
    Emoji.prototype.joy = 0;

    /**
     * Emoji sadness.
     * @member {number} sadness
     * @memberof uxlab.Emoji
     * @instance
     */
    Emoji.prototype.sadness = 0;

    /**
     * Emoji surprise.
     * @member {number} surprise
     * @memberof uxlab.Emoji
     * @instance
     */
    Emoji.prototype.surprise = 0;

    /**
     * Emoji valence.
     * @member {number} valence
     * @memberof uxlab.Emoji
     * @instance
     */
    Emoji.prototype.valence = 0;

    /**
     * Emoji brightness.
     * @member {number} brightness
     * @memberof uxlab.Emoji
     * @instance
     */
    Emoji.prototype.brightness = 0;

    /**
     * Creates a new Emoji instance using the specified properties.
     * @function create
     * @memberof uxlab.Emoji
     * @static
     * @param {uxlab.IEmoji=} [properties] Properties to set
     * @returns {uxlab.Emoji} Emoji instance
     */
    Emoji.create = function create(properties) {
      return new Emoji(properties);
    };

    /**
     * Encodes the specified Emoji message. Does not implicitly {@link uxlab.Emoji.verify|verify} messages.
     * @function encode
     * @memberof uxlab.Emoji
     * @static
     * @param {uxlab.IEmoji} message Emoji message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Emoji.encode = function encode(message, writer) {
      if (!writer) {
        writer = $Writer.create();
      }
      writer.uint32(/* id 1, wireType 0 = */ 8).int32(message.relativeTime);
      writer.uint32(/* id 2, wireType 0 = */ 16).int32(message.faceid);
      writer.uint32(/* id 3, wireType 0 = */ 24).int32(message.anger);
      writer.uint32(/* id 4, wireType 0 = */ 32).int32(message.contemt);
      writer.uint32(/* id 5, wireType 0 = */ 40).int32(message.disgust);
      writer.uint32(/* id 6, wireType 0 = */ 48).int32(message.engagement);
      writer.uint32(/* id 7, wireType 0 = */ 56).int32(message.fear);
      writer.uint32(/* id 8, wireType 0 = */ 64).int32(message.joy);
      writer.uint32(/* id 9, wireType 0 = */ 72).int32(message.sadness);
      writer.uint32(/* id 10, wireType 0 = */ 80).int32(message.surprise);
      writer.uint32(/* id 11, wireType 0 = */ 88).int32(message.valence);
      writer.uint32(/* id 12, wireType 0 = */ 96).int32(message.brightness);
      return writer;
    };

    /**
     * Encodes the specified Emoji message, length delimited. Does not implicitly {@link uxlab.Emoji.verify|verify} messages.
     * @function encodeDelimited
     * @memberof uxlab.Emoji
     * @static
     * @param {uxlab.IEmoji} message Emoji message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Emoji.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Emoji message from the specified reader or buffer.
     * @function decode
     * @memberof uxlab.Emoji
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {uxlab.Emoji} Emoji
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Emoji.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) {
        reader = $Reader.create(reader);
      }
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.uxlab.Emoji();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.relativeTime = reader.int32();
            break;
          case 2:
            message.faceid = reader.int32();
            break;
          case 3:
            message.anger = reader.int32();
            break;
          case 4:
            message.contemt = reader.int32();
            break;
          case 5:
            message.disgust = reader.int32();
            break;
          case 6:
            message.engagement = reader.int32();
            break;
          case 7:
            message.fear = reader.int32();
            break;
          case 8:
            message.joy = reader.int32();
            break;
          case 9:
            message.sadness = reader.int32();
            break;
          case 10:
            message.surprise = reader.int32();
            break;
          case 11:
            message.valence = reader.int32();
            break;
          case 12:
            message.brightness = reader.int32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      if (!message.hasOwnProperty('relativeTime')) {
        throw $util.ProtocolError("missing required 'relativeTime'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('faceid')) {
        throw $util.ProtocolError("missing required 'faceid'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('anger')) {
        throw $util.ProtocolError("missing required 'anger'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('contemt')) {
        throw $util.ProtocolError("missing required 'contemt'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('disgust')) {
        throw $util.ProtocolError("missing required 'disgust'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('engagement')) {
        throw $util.ProtocolError("missing required 'engagement'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('fear')) {
        throw $util.ProtocolError("missing required 'fear'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('joy')) {
        throw $util.ProtocolError("missing required 'joy'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('sadness')) {
        throw $util.ProtocolError("missing required 'sadness'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('surprise')) {
        throw $util.ProtocolError("missing required 'surprise'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('valence')) {
        throw $util.ProtocolError("missing required 'valence'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('brightness')) {
        throw $util.ProtocolError("missing required 'brightness'", {
          instance: message
        });
      }
      return message;
    };

    /**
     * Decodes an Emoji message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof uxlab.Emoji
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {uxlab.Emoji} Emoji
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Emoji.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) {
        reader = new $Reader(reader);
      }
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Emoji message.
     * @function verify
     * @memberof uxlab.Emoji
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Emoji.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) {
        return 'object expected';
      }
      if (!$util.isInteger(message.relativeTime)) {
        return 'relativeTime: integer expected';
      }
      if (!$util.isInteger(message.faceid)) {
        return 'faceid: integer expected';
      }
      if (!$util.isInteger(message.anger)) {
        return 'anger: integer expected';
      }
      if (!$util.isInteger(message.contemt)) {
        return 'contemt: integer expected';
      }
      if (!$util.isInteger(message.disgust)) {
        return 'disgust: integer expected';
      }
      if (!$util.isInteger(message.engagement)) {
        return 'engagement: integer expected';
      }
      if (!$util.isInteger(message.fear)) {
        return 'fear: integer expected';
      }
      if (!$util.isInteger(message.joy)) {
        return 'joy: integer expected';
      }
      if (!$util.isInteger(message.sadness)) {
        return 'sadness: integer expected';
      }
      if (!$util.isInteger(message.surprise)) {
        return 'surprise: integer expected';
      }
      if (!$util.isInteger(message.valence)) {
        return 'valence: integer expected';
      }
      if (!$util.isInteger(message.brightness)) {
        return 'brightness: integer expected';
      }
      return null;
    };

    /**
     * Creates an Emoji message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof uxlab.Emoji
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {uxlab.Emoji} Emoji
     */
    Emoji.fromObject = function fromObject(object) {
      if (object instanceof $root.uxlab.Emoji) {
        return object;
      }
      let message = new $root.uxlab.Emoji();
      if (object.relativeTime != null) {
        message.relativeTime = object.relativeTime | 0;
      }
      if (object.faceid != null) {
        message.faceid = object.faceid | 0;
      }
      if (object.anger != null) {
        message.anger = object.anger | 0;
      }
      if (object.contemt != null) {
        message.contemt = object.contemt | 0;
      }
      if (object.disgust != null) {
        message.disgust = object.disgust | 0;
      }
      if (object.engagement != null) {
        message.engagement = object.engagement | 0;
      }
      if (object.fear != null) {
        message.fear = object.fear | 0;
      }
      if (object.joy != null) {
        message.joy = object.joy | 0;
      }
      if (object.sadness != null) {
        message.sadness = object.sadness | 0;
      }
      if (object.surprise != null) {
        message.surprise = object.surprise | 0;
      }
      if (object.valence != null) {
        message.valence = object.valence | 0;
      }
      if (object.brightness != null) {
        message.brightness = object.brightness | 0;
      }
      return message;
    };

    /**
     * Creates a plain object from an Emoji message. Also converts values to other types if specified.
     * @function toObject
     * @memberof uxlab.Emoji
     * @static
     * @param {uxlab.Emoji} message Emoji
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Emoji.toObject = function toObject(message, options) {
      if (!options) {
        options = {};
      }
      let object = {};
      if (options.defaults) {
        object.relativeTime = 0;
        object.faceid = 0;
        object.anger = 0;
        object.contemt = 0;
        object.disgust = 0;
        object.engagement = 0;
        object.fear = 0;
        object.joy = 0;
        object.sadness = 0;
        object.surprise = 0;
        object.valence = 0;
        object.brightness = 0;
      }
      if (
        message.relativeTime != null &&
        message.hasOwnProperty('relativeTime')
      ) {
        object.relativeTime = message.relativeTime;
      }
      if (message.faceid != null && message.hasOwnProperty('faceid')) {
        object.faceid = message.faceid;
      }
      if (message.anger != null && message.hasOwnProperty('anger')) {
        object.anger = message.anger;
      }
      if (message.contemt != null && message.hasOwnProperty('contemt')) {
        object.contemt = message.contemt;
      }
      if (message.disgust != null && message.hasOwnProperty('disgust')) {
        object.disgust = message.disgust;
      }
      if (message.engagement != null && message.hasOwnProperty('engagement')) {
        object.engagement = message.engagement;
      }
      if (message.fear != null && message.hasOwnProperty('fear')) {
        object.fear = message.fear;
      }
      if (message.joy != null && message.hasOwnProperty('joy')) {
        object.joy = message.joy;
      }
      if (message.sadness != null && message.hasOwnProperty('sadness')) {
        object.sadness = message.sadness;
      }
      if (message.surprise != null && message.hasOwnProperty('surprise')) {
        object.surprise = message.surprise;
      }
      if (message.valence != null && message.hasOwnProperty('valence')) {
        object.valence = message.valence;
      }
      if (message.brightness != null && message.hasOwnProperty('brightness')) {
        object.brightness = message.brightness;
      }
      return object;
    };

    /**
     * Converts this Emoji to JSON.
     * @function toJSON
     * @memberof uxlab.Emoji
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Emoji.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Emoji;
  })();

  uxlab.Emojis = (function () {
    /**
     * Properties of an Emojis.
     * @memberof uxlab
     * @interface IEmojis
     * @property {Array.<uxlab.IEmoji>|null} [emoji] Emojis emoji
     */

    /**
     * Constructs a new Emojis.
     * @memberof uxlab
     * @classdesc Represents an Emojis.
     * @implements IEmojis
     * @constructor
     * @param {uxlab.IEmojis=} [properties] Properties to set
     */
    function Emojis(properties) {
      this.emoji = [];
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }

    /**
     * Emojis emoji.
     * @member {Array.<uxlab.IEmoji>} emoji
     * @memberof uxlab.Emojis
     * @instance
     */
    Emojis.prototype.emoji = $util.emptyArray;

    /**
     * Creates a new Emojis instance using the specified properties.
     * @function create
     * @memberof uxlab.Emojis
     * @static
     * @param {uxlab.IEmojis=} [properties] Properties to set
     * @returns {uxlab.Emojis} Emojis instance
     */
    Emojis.create = function create(properties) {
      return new Emojis(properties);
    };

    /**
     * Encodes the specified Emojis message. Does not implicitly {@link uxlab.Emojis.verify|verify} messages.
     * @function encode
     * @memberof uxlab.Emojis
     * @static
     * @param {uxlab.IEmojis} message Emojis message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Emojis.encode = function encode(message, writer) {
      if (!writer) {
        writer = $Writer.create();
      }
      if (message.emoji != null && message.emoji.length) {
        for (let i = 0; i < message.emoji.length; ++i) {
          $root.uxlab.Emoji.encode(
            message.emoji[i],
            writer.uint32(/* id 1, wireType 2 = */ 10).fork()
          ).ldelim();
        }
      }
      return writer;
    };

    /**
     * Encodes the specified Emojis message, length delimited. Does not implicitly {@link uxlab.Emojis.verify|verify} messages.
     * @function encodeDelimited
     * @memberof uxlab.Emojis
     * @static
     * @param {uxlab.IEmojis} message Emojis message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Emojis.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Emojis message from the specified reader or buffer.
     * @function decode
     * @memberof uxlab.Emojis
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {uxlab.Emojis} Emojis
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Emojis.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) {
        reader = $Reader.create(reader);
      }
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.uxlab.Emojis();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            if (!(message.emoji && message.emoji.length)) {
              message.emoji = [];
            }
            message.emoji.push(
              $root.uxlab.Emoji.decode(reader, reader.uint32())
            );
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an Emojis message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof uxlab.Emojis
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {uxlab.Emojis} Emojis
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Emojis.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) {
        reader = new $Reader(reader);
      }
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Emojis message.
     * @function verify
     * @memberof uxlab.Emojis
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Emojis.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) {
        return 'object expected';
      }
      if (message.emoji != null && message.hasOwnProperty('emoji')) {
        if (!Array.isArray(message.emoji)) {
          return 'emoji: array expected';
        }
        for (let i = 0; i < message.emoji.length; ++i) {
          let error = $root.uxlab.Emoji.verify(message.emoji[i]);
          if (error) {
            return `emoji.${error}`;
          }
        }
      }
      return null;
    };

    /**
     * Creates an Emojis message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof uxlab.Emojis
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {uxlab.Emojis} Emojis
     */
    Emojis.fromObject = function fromObject(object) {
      if (object instanceof $root.uxlab.Emojis) {
        return object;
      }
      let message = new $root.uxlab.Emojis();
      if (object.emoji) {
        if (!Array.isArray(object.emoji)) {
          throw TypeError('.uxlab.Emojis.emoji: array expected');
        }
        message.emoji = [];
        for (let i = 0; i < object.emoji.length; ++i) {
          if (typeof object.emoji[i] !== 'object') {
            throw TypeError('.uxlab.Emojis.emoji: object expected');
          }
          message.emoji[i] = $root.uxlab.Emoji.fromObject(object.emoji[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from an Emojis message. Also converts values to other types if specified.
     * @function toObject
     * @memberof uxlab.Emojis
     * @static
     * @param {uxlab.Emojis} message Emojis
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Emojis.toObject = function toObject(message, options) {
      if (!options) {
        options = {};
      }
      let object = {};
      if (options.arrays || options.defaults) {
        object.emoji = [];
      }
      if (message.emoji && message.emoji.length) {
        object.emoji = [];
        for (let j = 0; j < message.emoji.length; ++j) {
          object.emoji[j] = $root.uxlab.Emoji.toObject(
            message.emoji[j],
            options
          );
        }
      }
      return object;
    };

    /**
     * Converts this Emojis to JSON.
     * @function toJSON
     * @memberof uxlab.Emojis
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Emojis.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Emojis;
  })();

  uxlab.Heartbeat = (function () {
    /**
     * Properties of a Heartbeat.
     * @memberof uxlab
     * @interface IHeartbeat
     * @property {number} relativeTime Heartbeat relativeTime
     * @property {number} heartrate Heartbeat heartrate
     */

    /**
     * Constructs a new Heartbeat.
     * @memberof uxlab
     * @classdesc Represents a Heartbeat.
     * @implements IHeartbeat
     * @constructor
     * @param {uxlab.IHeartbeat=} [properties] Properties to set
     */
    function Heartbeat(properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }

    /**
     * Heartbeat relativeTime.
     * @member {number} relativeTime
     * @memberof uxlab.Heartbeat
     * @instance
     */
    Heartbeat.prototype.relativeTime = 0;

    /**
     * Heartbeat heartrate.
     * @member {number} heartrate
     * @memberof uxlab.Heartbeat
     * @instance
     */
    Heartbeat.prototype.heartrate = 0;

    /**
     * Creates a new Heartbeat instance using the specified properties.
     * @function create
     * @memberof uxlab.Heartbeat
     * @static
     * @param {uxlab.IHeartbeat=} [properties] Properties to set
     * @returns {uxlab.Heartbeat} Heartbeat instance
     */
    Heartbeat.create = function create(properties) {
      return new Heartbeat(properties);
    };

    /**
     * Encodes the specified Heartbeat message. Does not implicitly {@link uxlab.Heartbeat.verify|verify} messages.
     * @function encode
     * @memberof uxlab.Heartbeat
     * @static
     * @param {uxlab.IHeartbeat} message Heartbeat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Heartbeat.encode = function encode(message, writer) {
      if (!writer) {
        writer = $Writer.create();
      }
      writer.uint32(/* id 1, wireType 1 = */ 9).double(message.relativeTime);
      writer.uint32(/* id 2, wireType 0 = */ 16).int32(message.heartrate);
      return writer;
    };

    /**
     * Encodes the specified Heartbeat message, length delimited. Does not implicitly {@link uxlab.Heartbeat.verify|verify} messages.
     * @function encodeDelimited
     * @memberof uxlab.Heartbeat
     * @static
     * @param {uxlab.IHeartbeat} message Heartbeat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Heartbeat.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Heartbeat message from the specified reader or buffer.
     * @function decode
     * @memberof uxlab.Heartbeat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {uxlab.Heartbeat} Heartbeat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Heartbeat.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) {
        reader = $Reader.create(reader);
      }
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.uxlab.Heartbeat();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.relativeTime = reader.double();
            break;
          case 2:
            message.heartrate = reader.int32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      if (!message.hasOwnProperty('relativeTime')) {
        throw $util.ProtocolError("missing required 'relativeTime'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('heartrate')) {
        throw $util.ProtocolError("missing required 'heartrate'", {
          instance: message
        });
      }
      return message;
    };

    /**
     * Decodes a Heartbeat message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof uxlab.Heartbeat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {uxlab.Heartbeat} Heartbeat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Heartbeat.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) {
        reader = new $Reader(reader);
      }
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Heartbeat message.
     * @function verify
     * @memberof uxlab.Heartbeat
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Heartbeat.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) {
        return 'object expected';
      }
      if (typeof message.relativeTime !== 'number') {
        return 'relativeTime: number expected';
      }
      if (!$util.isInteger(message.heartrate)) {
        return 'heartrate: integer expected';
      }
      return null;
    };

    /**
     * Creates a Heartbeat message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof uxlab.Heartbeat
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {uxlab.Heartbeat} Heartbeat
     */
    Heartbeat.fromObject = function fromObject(object) {
      if (object instanceof $root.uxlab.Heartbeat) {
        return object;
      }
      let message = new $root.uxlab.Heartbeat();
      if (object.relativeTime != null) {
        message.relativeTime = Number(object.relativeTime);
      }
      if (object.heartrate != null) {
        message.heartrate = object.heartrate | 0;
      }
      return message;
    };

    /**
     * Creates a plain object from a Heartbeat message. Also converts values to other types if specified.
     * @function toObject
     * @memberof uxlab.Heartbeat
     * @static
     * @param {uxlab.Heartbeat} message Heartbeat
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Heartbeat.toObject = function toObject(message, options) {
      if (!options) {
        options = {};
      }
      let object = {};
      if (options.defaults) {
        object.relativeTime = 0;
        object.heartrate = 0;
      }
      if (
        message.relativeTime != null &&
        message.hasOwnProperty('relativeTime')
      ) {
        object.relativeTime =
          options.json && !isFinite(message.relativeTime)
            ? String(message.relativeTime)
            : message.relativeTime;
      }
      if (message.heartrate != null && message.hasOwnProperty('heartrate')) {
        object.heartrate = message.heartrate;
      }
      return object;
    };

    /**
     * Converts this Heartbeat to JSON.
     * @function toJSON
     * @memberof uxlab.Heartbeat
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Heartbeat.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Heartbeat;
  })();

  uxlab.Heartbeats = (function () {
    /**
     * Properties of a Heartbeats.
     * @memberof uxlab
     * @interface IHeartbeats
     * @property {Array.<uxlab.IHeartbeat>|null} [heatbeat] Heartbeats heatbeat
     */

    /**
     * Constructs a new Heartbeats.
     * @memberof uxlab
     * @classdesc Represents a Heartbeats.
     * @implements IHeartbeats
     * @constructor
     * @param {uxlab.IHeartbeats=} [properties] Properties to set
     */
    function Heartbeats(properties) {
      this.heatbeat = [];
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }

    /**
     * Heartbeats heatbeat.
     * @member {Array.<uxlab.IHeartbeat>} heatbeat
     * @memberof uxlab.Heartbeats
     * @instance
     */
    Heartbeats.prototype.heatbeat = $util.emptyArray;

    /**
     * Creates a new Heartbeats instance using the specified properties.
     * @function create
     * @memberof uxlab.Heartbeats
     * @static
     * @param {uxlab.IHeartbeats=} [properties] Properties to set
     * @returns {uxlab.Heartbeats} Heartbeats instance
     */
    Heartbeats.create = function create(properties) {
      return new Heartbeats(properties);
    };

    /**
     * Encodes the specified Heartbeats message. Does not implicitly {@link uxlab.Heartbeats.verify|verify} messages.
     * @function encode
     * @memberof uxlab.Heartbeats
     * @static
     * @param {uxlab.IHeartbeats} message Heartbeats message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Heartbeats.encode = function encode(message, writer) {
      if (!writer) {
        writer = $Writer.create();
      }
      if (message.heatbeat != null && message.heatbeat.length) {
        for (let i = 0; i < message.heatbeat.length; ++i) {
          $root.uxlab.Heartbeat.encode(
            message.heatbeat[i],
            writer.uint32(/* id 1, wireType 2 = */ 10).fork()
          ).ldelim();
        }
      }
      return writer;
    };

    /**
     * Encodes the specified Heartbeats message, length delimited. Does not implicitly {@link uxlab.Heartbeats.verify|verify} messages.
     * @function encodeDelimited
     * @memberof uxlab.Heartbeats
     * @static
     * @param {uxlab.IHeartbeats} message Heartbeats message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Heartbeats.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Heartbeats message from the specified reader or buffer.
     * @function decode
     * @memberof uxlab.Heartbeats
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {uxlab.Heartbeats} Heartbeats
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Heartbeats.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) {
        reader = $Reader.create(reader);
      }
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.uxlab.Heartbeats();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            if (!(message.heatbeat && message.heatbeat.length)) {
              message.heatbeat = [];
            }
            message.heatbeat.push(
              $root.uxlab.Heartbeat.decode(reader, reader.uint32())
            );
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a Heartbeats message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof uxlab.Heartbeats
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {uxlab.Heartbeats} Heartbeats
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Heartbeats.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) {
        reader = new $Reader(reader);
      }
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Heartbeats message.
     * @function verify
     * @memberof uxlab.Heartbeats
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Heartbeats.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) {
        return 'object expected';
      }
      if (message.heatbeat != null && message.hasOwnProperty('heatbeat')) {
        if (!Array.isArray(message.heatbeat)) {
          return 'heatbeat: array expected';
        }
        for (let i = 0; i < message.heatbeat.length; ++i) {
          let error = $root.uxlab.Heartbeat.verify(message.heatbeat[i]);
          if (error) {
            return `heatbeat.${error}`;
          }
        }
      }
      return null;
    };

    /**
     * Creates a Heartbeats message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof uxlab.Heartbeats
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {uxlab.Heartbeats} Heartbeats
     */
    Heartbeats.fromObject = function fromObject(object) {
      if (object instanceof $root.uxlab.Heartbeats) {
        return object;
      }
      let message = new $root.uxlab.Heartbeats();
      if (object.heatbeat) {
        if (!Array.isArray(object.heatbeat)) {
          throw TypeError('.uxlab.Heartbeats.heatbeat: array expected');
        }
        message.heatbeat = [];
        for (let i = 0; i < object.heatbeat.length; ++i) {
          if (typeof object.heatbeat[i] !== 'object') {
            throw TypeError('.uxlab.Heartbeats.heatbeat: object expected');
          }
          message.heatbeat[i] = $root.uxlab.Heartbeat.fromObject(
            object.heatbeat[i]
          );
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a Heartbeats message. Also converts values to other types if specified.
     * @function toObject
     * @memberof uxlab.Heartbeats
     * @static
     * @param {uxlab.Heartbeats} message Heartbeats
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Heartbeats.toObject = function toObject(message, options) {
      if (!options) {
        options = {};
      }
      let object = {};
      if (options.arrays || options.defaults) {
        object.heatbeat = [];
      }
      if (message.heatbeat && message.heatbeat.length) {
        object.heatbeat = [];
        for (let j = 0; j < message.heatbeat.length; ++j) {
          object.heatbeat[j] = $root.uxlab.Heartbeat.toObject(
            message.heatbeat[j],
            options
          );
        }
      }
      return object;
    };

    /**
     * Converts this Heartbeats to JSON.
     * @function toJSON
     * @memberof uxlab.Heartbeats
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Heartbeats.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Heartbeats;
  })();

  uxlab.KeyBoardEvent = (function () {
    /**
     * Properties of a KeyBoardEvent.
     * @memberof uxlab
     * @interface IKeyBoardEvent
     * @property {number} relativeTime KeyBoardEvent relativeTime
     * @property {string} value KeyBoardEvent value
     * @property {string} action KeyBoardEvent action
     */

    /**
     * Constructs a new KeyBoardEvent.
     * @memberof uxlab
     * @classdesc Represents a KeyBoardEvent.
     * @implements IKeyBoardEvent
     * @constructor
     * @param {uxlab.IKeyBoardEvent=} [properties] Properties to set
     */
    function KeyBoardEvent(properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }

    /**
     * KeyBoardEvent relativeTime.
     * @member {number} relativeTime
     * @memberof uxlab.KeyBoardEvent
     * @instance
     */
    KeyBoardEvent.prototype.relativeTime = 0;

    /**
     * KeyBoardEvent value.
     * @member {string} value
     * @memberof uxlab.KeyBoardEvent
     * @instance
     */
    KeyBoardEvent.prototype.value = '';

    /**
     * KeyBoardEvent action.
     * @member {string} action
     * @memberof uxlab.KeyBoardEvent
     * @instance
     */
    KeyBoardEvent.prototype.action = '';

    /**
     * Creates a new KeyBoardEvent instance using the specified properties.
     * @function create
     * @memberof uxlab.KeyBoardEvent
     * @static
     * @param {uxlab.IKeyBoardEvent=} [properties] Properties to set
     * @returns {uxlab.KeyBoardEvent} KeyBoardEvent instance
     */
    KeyBoardEvent.create = function create(properties) {
      return new KeyBoardEvent(properties);
    };

    /**
     * Encodes the specified KeyBoardEvent message. Does not implicitly {@link uxlab.KeyBoardEvent.verify|verify} messages.
     * @function encode
     * @memberof uxlab.KeyBoardEvent
     * @static
     * @param {uxlab.IKeyBoardEvent} message KeyBoardEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KeyBoardEvent.encode = function encode(message, writer) {
      if (!writer) {
        writer = $Writer.create();
      }
      writer.uint32(/* id 1, wireType 1 = */ 9).double(message.relativeTime);
      writer.uint32(/* id 2, wireType 2 = */ 18).string(message.value);
      writer.uint32(/* id 3, wireType 2 = */ 26).string(message.action);
      return writer;
    };

    /**
     * Encodes the specified KeyBoardEvent message, length delimited. Does not implicitly {@link uxlab.KeyBoardEvent.verify|verify} messages.
     * @function encodeDelimited
     * @memberof uxlab.KeyBoardEvent
     * @static
     * @param {uxlab.IKeyBoardEvent} message KeyBoardEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KeyBoardEvent.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a KeyBoardEvent message from the specified reader or buffer.
     * @function decode
     * @memberof uxlab.KeyBoardEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {uxlab.KeyBoardEvent} KeyBoardEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KeyBoardEvent.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) {
        reader = $Reader.create(reader);
      }
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.uxlab.KeyBoardEvent();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.relativeTime = reader.double();
            break;
          case 2:
            message.value = reader.string();
            break;
          case 3:
            message.action = reader.string();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      if (!message.hasOwnProperty('relativeTime')) {
        throw $util.ProtocolError("missing required 'relativeTime'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('value')) {
        throw $util.ProtocolError("missing required 'value'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('action')) {
        throw $util.ProtocolError("missing required 'action'", {
          instance: message
        });
      }
      return message;
    };

    /**
     * Decodes a KeyBoardEvent message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof uxlab.KeyBoardEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {uxlab.KeyBoardEvent} KeyBoardEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KeyBoardEvent.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) {
        reader = new $Reader(reader);
      }
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a KeyBoardEvent message.
     * @function verify
     * @memberof uxlab.KeyBoardEvent
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    KeyBoardEvent.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) {
        return 'object expected';
      }
      if (typeof message.relativeTime !== 'number') {
        return 'relativeTime: number expected';
      }
      if (!$util.isString(message.value)) {
        return 'value: string expected';
      }
      if (!$util.isString(message.action)) {
        return 'action: string expected';
      }
      return null;
    };

    /**
     * Creates a KeyBoardEvent message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof uxlab.KeyBoardEvent
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {uxlab.KeyBoardEvent} KeyBoardEvent
     */
    KeyBoardEvent.fromObject = function fromObject(object) {
      if (object instanceof $root.uxlab.KeyBoardEvent) {
        return object;
      }
      let message = new $root.uxlab.KeyBoardEvent();
      if (object.relativeTime != null) {
        message.relativeTime = Number(object.relativeTime);
      }
      if (object.value != null) {
        message.value = String(object.value);
      }
      if (object.action != null) {
        message.action = String(object.action);
      }
      return message;
    };

    /**
     * Creates a plain object from a KeyBoardEvent message. Also converts values to other types if specified.
     * @function toObject
     * @memberof uxlab.KeyBoardEvent
     * @static
     * @param {uxlab.KeyBoardEvent} message KeyBoardEvent
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    KeyBoardEvent.toObject = function toObject(message, options) {
      if (!options) {
        options = {};
      }
      let object = {};
      if (options.defaults) {
        object.relativeTime = 0;
        object.value = '';
        object.action = '';
      }
      if (
        message.relativeTime != null &&
        message.hasOwnProperty('relativeTime')
      ) {
        object.relativeTime =
          options.json && !isFinite(message.relativeTime)
            ? String(message.relativeTime)
            : message.relativeTime;
      }
      if (message.value != null && message.hasOwnProperty('value')) {
        object.value = message.value;
      }
      if (message.action != null && message.hasOwnProperty('action')) {
        object.action = message.action;
      }
      return object;
    };

    /**
     * Converts this KeyBoardEvent to JSON.
     * @function toJSON
     * @memberof uxlab.KeyBoardEvent
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    KeyBoardEvent.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return KeyBoardEvent;
  })();

  uxlab.KeyBoardEvents = (function () {
    /**
     * Properties of a KeyBoardEvents.
     * @memberof uxlab
     * @interface IKeyBoardEvents
     * @property {Array.<uxlab.IKeyBoardEvent>|null} [keyEvent] KeyBoardEvents keyEvent
     */

    /**
     * Constructs a new KeyBoardEvents.
     * @memberof uxlab
     * @classdesc Represents a KeyBoardEvents.
     * @implements IKeyBoardEvents
     * @constructor
     * @param {uxlab.IKeyBoardEvents=} [properties] Properties to set
     */
    function KeyBoardEvents(properties) {
      this.keyEvent = [];
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }

    /**
     * KeyBoardEvents keyEvent.
     * @member {Array.<uxlab.IKeyBoardEvent>} keyEvent
     * @memberof uxlab.KeyBoardEvents
     * @instance
     */
    KeyBoardEvents.prototype.keyEvent = $util.emptyArray;

    /**
     * Creates a new KeyBoardEvents instance using the specified properties.
     * @function create
     * @memberof uxlab.KeyBoardEvents
     * @static
     * @param {uxlab.IKeyBoardEvents=} [properties] Properties to set
     * @returns {uxlab.KeyBoardEvents} KeyBoardEvents instance
     */
    KeyBoardEvents.create = function create(properties) {
      return new KeyBoardEvents(properties);
    };

    /**
     * Encodes the specified KeyBoardEvents message. Does not implicitly {@link uxlab.KeyBoardEvents.verify|verify} messages.
     * @function encode
     * @memberof uxlab.KeyBoardEvents
     * @static
     * @param {uxlab.IKeyBoardEvents} message KeyBoardEvents message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KeyBoardEvents.encode = function encode(message, writer) {
      if (!writer) {
        writer = $Writer.create();
      }
      if (message.keyEvent != null && message.keyEvent.length) {
        for (let i = 0; i < message.keyEvent.length; ++i) {
          $root.uxlab.KeyBoardEvent.encode(
            message.keyEvent[i],
            writer.uint32(/* id 1, wireType 2 = */ 10).fork()
          ).ldelim();
        }
      }
      return writer;
    };

    /**
     * Encodes the specified KeyBoardEvents message, length delimited. Does not implicitly {@link uxlab.KeyBoardEvents.verify|verify} messages.
     * @function encodeDelimited
     * @memberof uxlab.KeyBoardEvents
     * @static
     * @param {uxlab.IKeyBoardEvents} message KeyBoardEvents message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KeyBoardEvents.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a KeyBoardEvents message from the specified reader or buffer.
     * @function decode
     * @memberof uxlab.KeyBoardEvents
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {uxlab.KeyBoardEvents} KeyBoardEvents
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KeyBoardEvents.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) {
        reader = $Reader.create(reader);
      }
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.uxlab.KeyBoardEvents();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            if (!(message.keyEvent && message.keyEvent.length)) {
              message.keyEvent = [];
            }
            message.keyEvent.push(
              $root.uxlab.KeyBoardEvent.decode(reader, reader.uint32())
            );
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a KeyBoardEvents message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof uxlab.KeyBoardEvents
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {uxlab.KeyBoardEvents} KeyBoardEvents
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KeyBoardEvents.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) {
        reader = new $Reader(reader);
      }
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a KeyBoardEvents message.
     * @function verify
     * @memberof uxlab.KeyBoardEvents
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    KeyBoardEvents.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) {
        return 'object expected';
      }
      if (message.keyEvent != null && message.hasOwnProperty('keyEvent')) {
        if (!Array.isArray(message.keyEvent)) {
          return 'keyEvent: array expected';
        }
        for (let i = 0; i < message.keyEvent.length; ++i) {
          let error = $root.uxlab.KeyBoardEvent.verify(message.keyEvent[i]);
          if (error) {
            return `keyEvent.${error}`;
          }
        }
      }
      return null;
    };

    /**
     * Creates a KeyBoardEvents message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof uxlab.KeyBoardEvents
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {uxlab.KeyBoardEvents} KeyBoardEvents
     */
    KeyBoardEvents.fromObject = function fromObject(object) {
      if (object instanceof $root.uxlab.KeyBoardEvents) {
        return object;
      }
      let message = new $root.uxlab.KeyBoardEvents();
      if (object.keyEvent) {
        if (!Array.isArray(object.keyEvent)) {
          throw TypeError('.uxlab.KeyBoardEvents.keyEvent: array expected');
        }
        message.keyEvent = [];
        for (let i = 0; i < object.keyEvent.length; ++i) {
          if (typeof object.keyEvent[i] !== 'object') {
            throw TypeError('.uxlab.KeyBoardEvents.keyEvent: object expected');
          }
          message.keyEvent[i] = $root.uxlab.KeyBoardEvent.fromObject(
            object.keyEvent[i]
          );
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a KeyBoardEvents message. Also converts values to other types if specified.
     * @function toObject
     * @memberof uxlab.KeyBoardEvents
     * @static
     * @param {uxlab.KeyBoardEvents} message KeyBoardEvents
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    KeyBoardEvents.toObject = function toObject(message, options) {
      if (!options) {
        options = {};
      }
      let object = {};
      if (options.arrays || options.defaults) {
        object.keyEvent = [];
      }
      if (message.keyEvent && message.keyEvent.length) {
        object.keyEvent = [];
        for (let j = 0; j < message.keyEvent.length; ++j) {
          object.keyEvent[j] = $root.uxlab.KeyBoardEvent.toObject(
            message.keyEvent[j],
            options
          );
        }
      }
      return object;
    };

    /**
     * Converts this KeyBoardEvents to JSON.
     * @function toJSON
     * @memberof uxlab.KeyBoardEvents
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    KeyBoardEvents.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return KeyBoardEvents;
  })();

  uxlab.Motion = (function () {
    /**
     * Properties of a Motion.
     * @memberof uxlab
     * @interface IMotion
     * @property {number} motionId Motion motionId
     * @property {number} motionType Motion motionType
     * @property {number} x Motion x
     * @property {number} y Motion y
     * @property {number} relativeTime Motion relativeTime
     * @property {number} add Motion add
     */

    /**
     * Constructs a new Motion.
     * @memberof uxlab
     * @classdesc Represents a Motion.
     * @implements IMotion
     * @constructor
     * @param {uxlab.IMotion=} [properties] Properties to set
     */
    function Motion(properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }

    /**
     * Motion motionId.
     * @member {number} motionId
     * @memberof uxlab.Motion
     * @instance
     */
    Motion.prototype.motionId = 0;

    /**
     * Motion motionType.
     * @member {number} motionType
     * @memberof uxlab.Motion
     * @instance
     */
    Motion.prototype.motionType = 0;

    /**
     * Motion x.
     * @member {number} x
     * @memberof uxlab.Motion
     * @instance
     */
    Motion.prototype.x = 0;

    /**
     * Motion y.
     * @member {number} y
     * @memberof uxlab.Motion
     * @instance
     */
    Motion.prototype.y = 0;

    /**
     * Motion relativeTime.
     * @member {number} relativeTime
     * @memberof uxlab.Motion
     * @instance
     */
    Motion.prototype.relativeTime = 0;

    /**
     * Motion add.
     * @member {number} add
     * @memberof uxlab.Motion
     * @instance
     */
    Motion.prototype.add = 0;

    /**
     * Creates a new Motion instance using the specified properties.
     * @function create
     * @memberof uxlab.Motion
     * @static
     * @param {uxlab.IMotion=} [properties] Properties to set
     * @returns {uxlab.Motion} Motion instance
     */
    Motion.create = function create(properties) {
      return new Motion(properties);
    };

    /**
     * Encodes the specified Motion message. Does not implicitly {@link uxlab.Motion.verify|verify} messages.
     * @function encode
     * @memberof uxlab.Motion
     * @static
     * @param {uxlab.IMotion} message Motion message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Motion.encode = function encode(message, writer) {
      if (!writer) {
        writer = $Writer.create();
      }
      writer.uint32(/* id 1, wireType 0 = */ 8).int32(message.motionId);
      writer.uint32(/* id 2, wireType 0 = */ 16).int32(message.motionType);
      writer.uint32(/* id 3, wireType 0 = */ 24).int32(message.x);
      writer.uint32(/* id 4, wireType 0 = */ 32).int32(message.y);
      writer.uint32(/* id 5, wireType 1 = */ 41).double(message.relativeTime);
      writer.uint32(/* id 6, wireType 0 = */ 48).int32(message.add);
      return writer;
    };

    /**
     * Encodes the specified Motion message, length delimited. Does not implicitly {@link uxlab.Motion.verify|verify} messages.
     * @function encodeDelimited
     * @memberof uxlab.Motion
     * @static
     * @param {uxlab.IMotion} message Motion message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Motion.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Motion message from the specified reader or buffer.
     * @function decode
     * @memberof uxlab.Motion
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {uxlab.Motion} Motion
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Motion.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) {
        reader = $Reader.create(reader);
      }
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.uxlab.Motion();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.motionId = reader.int32();
            break;
          case 2:
            message.motionType = reader.int32();
            break;
          case 3:
            message.x = reader.int32();
            break;
          case 4:
            message.y = reader.int32();
            break;
          case 5:
            message.relativeTime = reader.double();
            break;
          case 6:
            message.add = reader.int32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      if (!message.hasOwnProperty('motionId')) {
        throw $util.ProtocolError("missing required 'motionId'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('motionType')) {
        throw $util.ProtocolError("missing required 'motionType'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('x')) {
        throw $util.ProtocolError("missing required 'x'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('y')) {
        throw $util.ProtocolError("missing required 'y'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('relativeTime')) {
        throw $util.ProtocolError("missing required 'relativeTime'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('add')) {
        throw $util.ProtocolError("missing required 'add'", {
          instance: message
        });
      }
      return message;
    };

    /**
     * Decodes a Motion message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof uxlab.Motion
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {uxlab.Motion} Motion
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Motion.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) {
        reader = new $Reader(reader);
      }
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Motion message.
     * @function verify
     * @memberof uxlab.Motion
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Motion.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) {
        return 'object expected';
      }
      if (!$util.isInteger(message.motionId)) {
        return 'motionId: integer expected';
      }
      if (!$util.isInteger(message.motionType)) {
        return 'motionType: integer expected';
      }
      if (!$util.isInteger(message.x)) {
        return 'x: integer expected';
      }
      if (!$util.isInteger(message.y)) {
        return 'y: integer expected';
      }
      if (typeof message.relativeTime !== 'number') {
        return 'relativeTime: number expected';
      }
      if (!$util.isInteger(message.add)) {
        return 'add: integer expected';
      }
      return null;
    };

    /**
     * Creates a Motion message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof uxlab.Motion
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {uxlab.Motion} Motion
     */
    Motion.fromObject = function fromObject(object) {
      if (object instanceof $root.uxlab.Motion) {
        return object;
      }
      let message = new $root.uxlab.Motion();
      if (object.motionId != null) {
        message.motionId = object.motionId | 0;
      }
      if (object.motionType != null) {
        message.motionType = object.motionType | 0;
      }
      if (object.x != null) {
        message.x = object.x | 0;
      }
      if (object.y != null) {
        message.y = object.y | 0;
      }
      if (object.relativeTime != null) {
        message.relativeTime = Number(object.relativeTime);
      }
      if (object.add != null) {
        message.add = object.add | 0;
      }
      return message;
    };

    /**
     * Creates a plain object from a Motion message. Also converts values to other types if specified.
     * @function toObject
     * @memberof uxlab.Motion
     * @static
     * @param {uxlab.Motion} message Motion
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Motion.toObject = function toObject(message, options) {
      if (!options) {
        options = {};
      }
      let object = {};
      if (options.defaults) {
        object.motionId = 0;
        object.motionType = 0;
        object.x = 0;
        object.y = 0;
        object.relativeTime = 0;
        object.add = 0;
      }
      if (message.motionId != null && message.hasOwnProperty('motionId')) {
        object.motionId = message.motionId;
      }
      if (message.motionType != null && message.hasOwnProperty('motionType')) {
        object.motionType = message.motionType;
      }
      if (message.x != null && message.hasOwnProperty('x')) {
        object.x = message.x;
      }
      if (message.y != null && message.hasOwnProperty('y')) {
        object.y = message.y;
      }
      if (
        message.relativeTime != null &&
        message.hasOwnProperty('relativeTime')
      ) {
        object.relativeTime =
          options.json && !isFinite(message.relativeTime)
            ? String(message.relativeTime)
            : message.relativeTime;
      }
      if (message.add != null && message.hasOwnProperty('add')) {
        object.add = message.add;
      }
      return object;
    };

    /**
     * Converts this Motion to JSON.
     * @function toJSON
     * @memberof uxlab.Motion
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Motion.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Motion;
  })();

  uxlab.Motions = (function () {
    /**
     * Properties of a Motions.
     * @memberof uxlab
     * @interface IMotions
     * @property {Array.<uxlab.IMotion>|null} [motion] Motions motion
     */

    /**
     * Constructs a new Motions.
     * @memberof uxlab
     * @classdesc Represents a Motions.
     * @implements IMotions
     * @constructor
     * @param {uxlab.IMotions=} [properties] Properties to set
     */
    function Motions(properties) {
      this.motion = [];
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }

    /**
     * Motions motion.
     * @member {Array.<uxlab.IMotion>} motion
     * @memberof uxlab.Motions
     * @instance
     */
    Motions.prototype.motion = $util.emptyArray;

    /**
     * Creates a new Motions instance using the specified properties.
     * @function create
     * @memberof uxlab.Motions
     * @static
     * @param {uxlab.IMotions=} [properties] Properties to set
     * @returns {uxlab.Motions} Motions instance
     */
    Motions.create = function create(properties) {
      return new Motions(properties);
    };

    /**
     * Encodes the specified Motions message. Does not implicitly {@link uxlab.Motions.verify|verify} messages.
     * @function encode
     * @memberof uxlab.Motions
     * @static
     * @param {uxlab.IMotions} message Motions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Motions.encode = function encode(message, writer) {
      if (!writer) {
        writer = $Writer.create();
      }
      if (message.motion != null && message.motion.length) {
        for (let i = 0; i < message.motion.length; ++i) {
          $root.uxlab.Motion.encode(
            message.motion[i],
            writer.uint32(/* id 1, wireType 2 = */ 10).fork()
          ).ldelim();
        }
      }
      return writer;
    };

    /**
     * Encodes the specified Motions message, length delimited. Does not implicitly {@link uxlab.Motions.verify|verify} messages.
     * @function encodeDelimited
     * @memberof uxlab.Motions
     * @static
     * @param {uxlab.IMotions} message Motions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Motions.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Motions message from the specified reader or buffer.
     * @function decode
     * @memberof uxlab.Motions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {uxlab.Motions} Motions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Motions.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) {
        reader = $Reader.create(reader);
      }
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.uxlab.Motions();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            if (!(message.motion && message.motion.length)) {
              message.motion = [];
            }
            message.motion.push(
              $root.uxlab.Motion.decode(reader, reader.uint32())
            );
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a Motions message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof uxlab.Motions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {uxlab.Motions} Motions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Motions.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) {
        reader = new $Reader(reader);
      }
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Motions message.
     * @function verify
     * @memberof uxlab.Motions
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Motions.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) {
        return 'object expected';
      }
      if (message.motion != null && message.hasOwnProperty('motion')) {
        if (!Array.isArray(message.motion)) {
          return 'motion: array expected';
        }
        for (let i = 0; i < message.motion.length; ++i) {
          let error = $root.uxlab.Motion.verify(message.motion[i]);
          if (error) {
            return `motion.${error}`;
          }
        }
      }
      return null;
    };

    /**
     * Creates a Motions message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof uxlab.Motions
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {uxlab.Motions} Motions
     */
    Motions.fromObject = function fromObject(object) {
      if (object instanceof $root.uxlab.Motions) {
        return object;
      }
      let message = new $root.uxlab.Motions();
      if (object.motion) {
        if (!Array.isArray(object.motion)) {
          throw TypeError('.uxlab.Motions.motion: array expected');
        }
        message.motion = [];
        for (let i = 0; i < object.motion.length; ++i) {
          if (typeof object.motion[i] !== 'object') {
            throw TypeError('.uxlab.Motions.motion: object expected');
          }
          message.motion[i] = $root.uxlab.Motion.fromObject(object.motion[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a Motions message. Also converts values to other types if specified.
     * @function toObject
     * @memberof uxlab.Motions
     * @static
     * @param {uxlab.Motions} message Motions
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Motions.toObject = function toObject(message, options) {
      if (!options) {
        options = {};
      }
      let object = {};
      if (options.arrays || options.defaults) {
        object.motion = [];
      }
      if (message.motion && message.motion.length) {
        object.motion = [];
        for (let j = 0; j < message.motion.length; ++j) {
          object.motion[j] = $root.uxlab.Motion.toObject(
            message.motion[j],
            options
          );
        }
      }
      return object;
    };

    /**
     * Converts this Motions to JSON.
     * @function toJSON
     * @memberof uxlab.Motions
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Motions.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Motions;
  })();

  uxlab.MouseEvent = (function () {
    /**
     * Properties of a MouseEvent.
     * @memberof uxlab
     * @interface IMouseEvent
     * @property {number} relativeTime MouseEvent relativeTime
     * @property {number} x MouseEvent x
     * @property {number} y MouseEvent y
     * @property {string} action MouseEvent action
     */

    /**
     * Constructs a new MouseEvent.
     * @memberof uxlab
     * @classdesc Represents a MouseEvent.
     * @implements IMouseEvent
     * @constructor
     * @param {uxlab.IMouseEvent=} [properties] Properties to set
     */
    function MouseEvent(properties) {
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }

    /**
     * MouseEvent relativeTime.
     * @member {number} relativeTime
     * @memberof uxlab.MouseEvent
     * @instance
     */
    MouseEvent.prototype.relativeTime = 0;

    /**
     * MouseEvent x.
     * @member {number} x
     * @memberof uxlab.MouseEvent
     * @instance
     */
    MouseEvent.prototype.x = 0;

    /**
     * MouseEvent y.
     * @member {number} y
     * @memberof uxlab.MouseEvent
     * @instance
     */
    MouseEvent.prototype.y = 0;

    /**
     * MouseEvent action.
     * @member {string} action
     * @memberof uxlab.MouseEvent
     * @instance
     */
    MouseEvent.prototype.action = '';

    /**
     * Creates a new MouseEvent instance using the specified properties.
     * @function create
     * @memberof uxlab.MouseEvent
     * @static
     * @param {uxlab.IMouseEvent=} [properties] Properties to set
     * @returns {uxlab.MouseEvent} MouseEvent instance
     */
    MouseEvent.create = function create(properties) {
      return new MouseEvent(properties);
    };

    /**
     * Encodes the specified MouseEvent message. Does not implicitly {@link uxlab.MouseEvent.verify|verify} messages.
     * @function encode
     * @memberof uxlab.MouseEvent
     * @static
     * @param {uxlab.IMouseEvent} message MouseEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MouseEvent.encode = function encode(message, writer) {
      if (!writer) {
        writer = $Writer.create();
      }
      writer.uint32(/* id 1, wireType 1 = */ 9).double(message.relativeTime);
      writer.uint32(/* id 2, wireType 0 = */ 16).int32(message.x);
      writer.uint32(/* id 3, wireType 0 = */ 24).int32(message.y);
      writer.uint32(/* id 4, wireType 2 = */ 34).string(message.action);
      return writer;
    };

    /**
     * Encodes the specified MouseEvent message, length delimited. Does not implicitly {@link uxlab.MouseEvent.verify|verify} messages.
     * @function encodeDelimited
     * @memberof uxlab.MouseEvent
     * @static
     * @param {uxlab.IMouseEvent} message MouseEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MouseEvent.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MouseEvent message from the specified reader or buffer.
     * @function decode
     * @memberof uxlab.MouseEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {uxlab.MouseEvent} MouseEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MouseEvent.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) {
        reader = $Reader.create(reader);
      }
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.uxlab.MouseEvent();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.relativeTime = reader.double();
            break;
          case 2:
            message.x = reader.int32();
            break;
          case 3:
            message.y = reader.int32();
            break;
          case 4:
            message.action = reader.string();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      if (!message.hasOwnProperty('relativeTime')) {
        throw $util.ProtocolError("missing required 'relativeTime'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('x')) {
        throw $util.ProtocolError("missing required 'x'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('y')) {
        throw $util.ProtocolError("missing required 'y'", {
          instance: message
        });
      }
      if (!message.hasOwnProperty('action')) {
        throw $util.ProtocolError("missing required 'action'", {
          instance: message
        });
      }
      return message;
    };

    /**
     * Decodes a MouseEvent message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof uxlab.MouseEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {uxlab.MouseEvent} MouseEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MouseEvent.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) {
        reader = new $Reader(reader);
      }
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MouseEvent message.
     * @function verify
     * @memberof uxlab.MouseEvent
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MouseEvent.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) {
        return 'object expected';
      }
      if (typeof message.relativeTime !== 'number') {
        return 'relativeTime: number expected';
      }
      if (!$util.isInteger(message.x)) {
        return 'x: integer expected';
      }
      if (!$util.isInteger(message.y)) {
        return 'y: integer expected';
      }
      if (!$util.isString(message.action)) {
        return 'action: string expected';
      }
      return null;
    };

    /**
     * Creates a MouseEvent message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof uxlab.MouseEvent
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {uxlab.MouseEvent} MouseEvent
     */
    MouseEvent.fromObject = function fromObject(object) {
      if (object instanceof $root.uxlab.MouseEvent) {
        return object;
      }
      let message = new $root.uxlab.MouseEvent();
      if (object.relativeTime != null) {
        message.relativeTime = Number(object.relativeTime);
      }
      if (object.x != null) {
        message.x = object.x | 0;
      }
      if (object.y != null) {
        message.y = object.y | 0;
      }
      if (object.action != null) {
        message.action = String(object.action);
      }
      return message;
    };

    /**
     * Creates a plain object from a MouseEvent message. Also converts values to other types if specified.
     * @function toObject
     * @memberof uxlab.MouseEvent
     * @static
     * @param {uxlab.MouseEvent} message MouseEvent
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MouseEvent.toObject = function toObject(message, options) {
      if (!options) {
        options = {};
      }
      let object = {};
      if (options.defaults) {
        object.relativeTime = 0;
        object.x = 0;
        object.y = 0;
        object.action = '';
      }
      if (
        message.relativeTime != null &&
        message.hasOwnProperty('relativeTime')
      ) {
        object.relativeTime =
          options.json && !isFinite(message.relativeTime)
            ? String(message.relativeTime)
            : message.relativeTime;
      }
      if (message.x != null && message.hasOwnProperty('x')) {
        object.x = message.x;
      }
      if (message.y != null && message.hasOwnProperty('y')) {
        object.y = message.y;
      }
      if (message.action != null && message.hasOwnProperty('action')) {
        object.action = message.action;
      }
      return object;
    };

    /**
     * Converts this MouseEvent to JSON.
     * @function toJSON
     * @memberof uxlab.MouseEvent
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MouseEvent.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return MouseEvent;
  })();

  uxlab.MouseEvents = (function () {
    /**
     * Properties of a MouseEvents.
     * @memberof uxlab
     * @interface IMouseEvents
     * @property {Array.<uxlab.IMouseEvent>|null} [mouseEvent] MouseEvents mouseEvent
     */

    /**
     * Constructs a new MouseEvents.
     * @memberof uxlab
     * @classdesc Represents a MouseEvents.
     * @implements IMouseEvents
     * @constructor
     * @param {uxlab.IMouseEvents=} [properties] Properties to set
     */
    function MouseEvents(properties) {
      this.mouseEvent = [];
      if (properties) {
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }

    /**
     * MouseEvents mouseEvent.
     * @member {Array.<uxlab.IMouseEvent>} mouseEvent
     * @memberof uxlab.MouseEvents
     * @instance
     */
    MouseEvents.prototype.mouseEvent = $util.emptyArray;

    /**
     * Creates a new MouseEvents instance using the specified properties.
     * @function create
     * @memberof uxlab.MouseEvents
     * @static
     * @param {uxlab.IMouseEvents=} [properties] Properties to set
     * @returns {uxlab.MouseEvents} MouseEvents instance
     */
    MouseEvents.create = function create(properties) {
      return new MouseEvents(properties);
    };

    /**
     * Encodes the specified MouseEvents message. Does not implicitly {@link uxlab.MouseEvents.verify|verify} messages.
     * @function encode
     * @memberof uxlab.MouseEvents
     * @static
     * @param {uxlab.IMouseEvents} message MouseEvents message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MouseEvents.encode = function encode(message, writer) {
      if (!writer) {
        writer = $Writer.create();
      }
      if (message.mouseEvent != null && message.mouseEvent.length) {
        for (let i = 0; i < message.mouseEvent.length; ++i) {
          $root.uxlab.MouseEvent.encode(
            message.mouseEvent[i],
            writer.uint32(/* id 1, wireType 2 = */ 10).fork()
          ).ldelim();
        }
      }
      return writer;
    };

    /**
     * Encodes the specified MouseEvents message, length delimited. Does not implicitly {@link uxlab.MouseEvents.verify|verify} messages.
     * @function encodeDelimited
     * @memberof uxlab.MouseEvents
     * @static
     * @param {uxlab.IMouseEvents} message MouseEvents message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MouseEvents.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MouseEvents message from the specified reader or buffer.
     * @function decode
     * @memberof uxlab.MouseEvents
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {uxlab.MouseEvents} MouseEvents
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MouseEvents.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) {
        reader = $Reader.create(reader);
      }
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.uxlab.MouseEvents();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            if (!(message.mouseEvent && message.mouseEvent.length)) {
              message.mouseEvent = [];
            }
            message.mouseEvent.push(
              $root.uxlab.MouseEvent.decode(reader, reader.uint32())
            );
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MouseEvents message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof uxlab.MouseEvents
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {uxlab.MouseEvents} MouseEvents
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MouseEvents.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) {
        reader = new $Reader(reader);
      }
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MouseEvents message.
     * @function verify
     * @memberof uxlab.MouseEvents
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MouseEvents.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) {
        return 'object expected';
      }
      if (message.mouseEvent != null && message.hasOwnProperty('mouseEvent')) {
        if (!Array.isArray(message.mouseEvent)) {
          return 'mouseEvent: array expected';
        }
        for (let i = 0; i < message.mouseEvent.length; ++i) {
          let error = $root.uxlab.MouseEvent.verify(message.mouseEvent[i]);
          if (error) {
            return `mouseEvent.${error}`;
          }
        }
      }
      return null;
    };

    /**
     * Creates a MouseEvents message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof uxlab.MouseEvents
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {uxlab.MouseEvents} MouseEvents
     */
    MouseEvents.fromObject = function fromObject(object) {
      if (object instanceof $root.uxlab.MouseEvents) {
        return object;
      }
      let message = new $root.uxlab.MouseEvents();
      if (object.mouseEvent) {
        if (!Array.isArray(object.mouseEvent)) {
          throw TypeError('.uxlab.MouseEvents.mouseEvent: array expected');
        }
        message.mouseEvent = [];
        for (let i = 0; i < object.mouseEvent.length; ++i) {
          if (typeof object.mouseEvent[i] !== 'object') {
            throw TypeError('.uxlab.MouseEvents.mouseEvent: object expected');
          }
          message.mouseEvent[i] = $root.uxlab.MouseEvent.fromObject(
            object.mouseEvent[i]
          );
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a MouseEvents message. Also converts values to other types if specified.
     * @function toObject
     * @memberof uxlab.MouseEvents
     * @static
     * @param {uxlab.MouseEvents} message MouseEvents
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MouseEvents.toObject = function toObject(message, options) {
      if (!options) {
        options = {};
      }
      let object = {};
      if (options.arrays || options.defaults) {
        object.mouseEvent = [];
      }
      if (message.mouseEvent && message.mouseEvent.length) {
        object.mouseEvent = [];
        for (let j = 0; j < message.mouseEvent.length; ++j) {
          object.mouseEvent[j] = $root.uxlab.MouseEvent.toObject(
            message.mouseEvent[j],
            options
          );
        }
      }
      return object;
    };

    /**
     * Converts this MouseEvents to JSON.
     * @function toJSON
     * @memberof uxlab.MouseEvents
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MouseEvents.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return MouseEvents;
  })();

  return uxlab;
})());

export { $root as default };

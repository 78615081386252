import { isInternet } from '@yum/myconfig'

interface ruazhi {
    id: any,
    name: string,
    file: Blob | undefined,
    task_id: any | undefined,
    player_id: any | undefined,
    test_id: any | undefined,
    start_time: string | undefined,
    stop_time: string | undefined,
    resolution_w: any | undefined,
    mysessionid: string,
    resolution_h: any | undefined,
    is_landscape: undefined,
    has_face_vid: any | undefined,
    has_game_vid: any | undefined,
    has_android_vid: any | undefined,
    has_pc_vid: any | undefined,
    emoji_length: any | undefined,
    heatmap_length: any | undefined,
    heartbeat_length: any | undefined,
    platform_type: any | undefined,
    ukey: string,
    retShaList: any,
    req_id: any | undefined,
    end_time: any | undefined,
    has_heatmap_vid: any | undefined,
    fileSize: any,
    blockNum: any,
    curBlockSize: any,
    videoId: any,
    video_type_index: any,//这个是用来标识当前视频的类型，0 1 2是各类视频，4是心率
    seqNum: any | undefined,
    file_name: any | undefined,

}
export enum ReUploadType {
    UNDEFINE = -1, VIDEOBASE_ANDROID_PC = 1, VIDEO_FACE = 0, VIDEO_HAND = 2, VIDEO_BACK = 3, HEART_DATA = 4, EMOTION_DATA = 5, HEATMAP_DATA = 6, KEY_DATA = 7, MOUSE_DATA = 8
    , MAXNUM = 10
}
//VIDEOBASE_FORANDROID是安卓的视频信息，同时也是保存了最初始的信息，其他类型的数据需要从这里复制一遍信息
export class SaveForReUpload {
    private static _used_dbs: Array<number> = []

    private _current_db: any = undefined
    private _db_index: ReUploadType = ReUploadType.UNDEFINE;
    private _file_buff: Array<any> = []//这个是每次写数据库之前的缓存
    public MAXNUMFORUPLOAD: number;//最大这么多数据会自动上传
    private _lastid = -1
    private start_adding: Array<any> = [];
    private adding_lock = false;
    constructor(num: ReUploadType = ReUploadType.VIDEOBASE_ANDROID_PC, MAXNUMFORUPLOAD = 60) {
        if (!isInternet)//测试服
        {
            MAXNUMFORUPLOAD = 10
        }
        this.MAXNUMFORUPLOAD = MAXNUMFORUPLOAD
        if (SaveForReUpload._used_dbs.indexOf(num) == -1)
            SaveForReUpload._used_dbs.push(num)
        else
            console.warn("注意，当前IndexDB.GetInstance序号重复")

        this._db_index = num
        this._current_db = IndexDB.GetInstance(num)
        let du_buff = IndexDB.GetInstance()
        let video_buff = this._current_db?.getVideoData()
        video_buff = new VideoData(du_buff?.getVideoData())//这里因为有些参数是可以复用的，因此直接复用单例的内容

        if (video_buff) {
            video_buff.video_type_index = this._db_index
            video_buff.file = []
            // video_buff.ukey=""
            // video_buff.videoId=""
            // video_buff.name=""
            this._current_db.setVideoData(video_buff)
        }

        setInterval(async () => {
            if (this.start_adding.length > 0 && !this.adding_lock) {
                //不能直接放到AddData里面，如果有持续大量数据进来会有问题，需要加锁
                this.adding_lock = true
                this.start_adding.pop();
                let buff = await this._current_db?.addToDB(this._lastid)
                this.adding_lock = false
                this._lastid = buff;
            }
        }, 2000)

    }
    public async AddData(obj: any)//每次都会向固定的id修改，如果没有id就会创建
    {
        this._file_buff.push(obj)

        if (this._file_buff.length > this.MAXNUMFORUPLOAD) {

            let video_buff = this._current_db?.getVideoData()
            video_buff.file.push(...this._file_buff)
            this._file_buff = []
            this._current_db.setVideoData(video_buff)//这一步应该可以省略，以防万一
            this.start_adding.push(0)

        }

    }

}
export class VideoData implements ruazhi {


    constructor(data: ruazhi | undefined) {

        this.ukey = ""
        this.name = ""
        this.mysessionid = ""
        if (data) {
            let buff = (data as any)
            let that = (this as any)
            for (let key in data) {
                that[key] = buff[key]                                // do something
            }
        }

    }
    video_type_index: any //视频类型索引 （0-人脸视频，1-游戏视频，2-手势视频）
    stop_time: string | undefined
    id: any
    name: string
    file: Blob | undefined
    task_id: any
    player_id: any
    test_id: any
    start_time: string | undefined
    resolution_w: any
    mysessionid: string
    resolution_h: any
    is_landscape: undefined
    has_face_vid: any
    has_game_vid: any
    has_android_vid: any
    has_pc_vid: any
    emoji_length: any
    heatmap_length: any
    heartbeat_length: any
    platform_type: any
    ukey: string
    retShaList: any
    req_id: any
    end_time: any
    has_heatmap_vid: any
    fileSize: any
    blockNum: any
    curBlockSize: any
    videoId: any
    seqNum: any
    file_name: any | undefined
    public GetObj() {

        let rtbuff: Partial<ruazhi> = {
            //id: "",这里不能有id，会冲突
            name: "",
            file: undefined,
            task_id: undefined,
            player_id: undefined,
            test_id: undefined,
            stop_time: undefined,
            start_time: undefined,
            resolution_w: undefined,
            mysessionid: "",
            resolution_h: undefined,
            is_landscape: undefined,
            has_face_vid: undefined,
            has_game_vid: undefined,
            emoji_length: undefined,
            heatmap_length: undefined,
            heartbeat_length: undefined,
            platform_type: undefined,
            ukey: "",
            retShaList: undefined,
            req_id: undefined,
            end_time: undefined,
            has_heatmap_vid: undefined,
            fileSize: undefined,
            blockNum: undefined,
            curBlockSize: undefined,
            videoId: undefined,
            video_type_index: undefined,
            seqNum: undefined,
            file_name: undefined,
            has_android_vid: undefined,
            has_pc_vid: undefined
        }

        for (let key in rtbuff) {

            (rtbuff as any)[key] = (this as any)[key]                                // do something
        }
        return rtbuff
    }

}
export class IndexDB {

    private m_videoData_phone: VideoData | undefined//VideoData分成三部分，一部分是点击结束之后立即可以保存的，这个数据可以让所有indexdb实例继承，还有一部分是各个模块自己上报的特有数据，需要等待模块返回后再保存
    //最后一部分是最终上报需要用到的，他需要等待某一个模块的结果。现在处理的方式是，1/2部分是在同一个时间轴上，第二个模块收到数据就直接保存，因为要尽量早的保存，防止用户崩溃了。第三部分再保存之后更新进去，就算崩溃了，也只是缺这个数据。


    private db: IDBDatabase | null | undefined;
    private m_addSvpShaSeqNumListToId: number = -1;
    private DBname = "gptlabVedios_test"
    private m_initaled: boolean = false;

    private m_fileLastTimeOfHours: number = 24
    static s_current_ids = new Array<number>()
    static indexDB_instancePool: IndexDB[] | null
    private m_transactionLock = false;//事务锁，每次申请trans后要
    private static s_username = undefined;
    public static GetInstance(index: ReUploadType = ReUploadType.VIDEOBASE_ANDROID_PC, num: number = 10, username: any = undefined): IndexDB {//0-人脸视频，1-游戏视频，2-手势视频，4存心率数据
        //这里默认number==1，也是因为0-人脸视频，1-游戏视频，2-手势视频，其中0/2有可能没有，按理来说应该把游戏视频设置为0，但是历史遗留原因，就没有改
        if (IndexDB.s_username == undefined) {
            IndexDB.s_username = username;
            console.log(1111, "sets_username", IndexDB.s_username)
        }

        if (!(typeof window !== 'undefined' && window.indexedDB)) {
            alert("请升级您的浏览器")
        }
        if (!IndexDB.indexDB_instancePool) {
            IndexDB.indexDB_instancePool = new Array<IndexDB>()
            for (let index = 0; index < num; index++) {
                IndexDB.indexDB_instancePool.push(new IndexDB());
            }

        }
        let rtbuff = IndexDB.indexDB_instancePool[index]
        if (rtbuff)
            return rtbuff
        else {
            alert("IndexDB 超限")
            return new IndexDB()
        }
    }
    public AddDataToAllForThisTestDB(obj: { [x: string]: any }) {//这个是向本次测试已经存了的所有数据更新,因为有一个字段是最后才能获取到的
        let store = this.db?.transaction([this.DBname], 'readwrite').objectStore(this.DBname)

        for (let index = 0; index < IndexDB.s_current_ids.length; index++) {
            const id = IndexDB.s_current_ids[index];
            if (id) {
                let request = store?.get(id)
                if (request) {
                    request.onsuccess = function (event) {
                        // 获取我们想要更新的数据

                        var data = request?.result;
                        if (data) {
                            for (let key in obj) {
                                // 更新你想修改的数据
                                if (data[key])
                                    data[key] = obj[key];
                            }
                            // 把更新过的对象放回数据库
                            store?.put(data);
                        }


                    };
                }


            }
        }


    }
    public constructor() {
        //打开数据库，或者直接连接数据库参数：数据库名称，版本，概述，大小
        //如果数据库不存在那么创建之
        this.m_videoData_phone = new VideoData(undefined)

        if (typeof window != "undefined" && window.indexedDB) {


            const request = window.indexedDB.open(this.DBname, 1);
            request.onerror = function (event) { }
            let that = this
            request.onsuccess = function (event) {

                that.db = request.result//可以拿到数据库对象

                that.m_initaled = true;

            }
            //如果指定的版本号，大于数据库的实际版本号，就会发生数据库升级事件upgradeneeded
            request.onupgradeneeded = function (event) {
                that.db = request.result;
                if (!that?.db?.objectStoreNames.contains(that.DBname)) {//判断是否存在

                    that?.db?.createObjectStore(that.DBname, { keyPath: 'id', autoIncrement: true });
                    that.m_initaled = true;
                    //自动生成主键db.createObjectStore(
                    //  'person',
                    //  { autoIncrement: true }
                    //);
                }

                //新建索引，参数索引名称、索引所在的属性、配置对象
                //objectStore.createIndex('email', 'email', { unique: true });
            }
            request.onerror = function (event) {
                console.error("indexedDB初始化错误", event)
            }

        }
        else {

            console.error("浏览器不支持indexedDB")
        }


    }
    // GET/POST /api/v1/updatetestinfo/
    //task_id: 任务id player_id : 被测玩家id test_id: 测试id start_time：测试开始时间 end_time: 测试结束时间 test_duration:测试时长（单位：秒） resolution_w： 宽 resolution_h： 高 is_landscape: 游戏视频是否横屏（1-横屏，0-竖屏）
    // has_face_vid: 本次测试是否包含人脸视频（1-包含，0-不包含） has_game_vid: 本次测试是否包含游戏视频（1-包含，0-不包含） has_heatmap_vid : 本次测试是否包含手势视频（1-包含，0-不包含） 
    // emoji_length： 本次测试待上传表情记录总数（若不上传数据，emoji_length值为-1） heatmap_length： 本次测试待上传热力图记录总数（若不上传数据，heatmap_length值为-1） 
    // heartbeat_length: 本次测试待上传心跳记录总数（若不上传数据，heartbeat_length值为-1） (2020-04-19更新)  platform_type：测试设备类型： 0：Android, 1:IOS, 2: PC, 3:Switch, 4: PS4, 5: Xbox (2020-04-19更新)
    public getVideoData() {
        return this.m_videoData_phone
    }
    public setVideoData(buff: VideoData | undefined) {
        this.m_videoData_phone = buff
    }
    public async addSvpShaSeqNumListToDB(obj: any) {
        //obj暂定是对象，类似
        // svpShaSeqNumList:[
        //     {
        //         "partNum": 1,
        //         "partSha": "cb798bf6499ef624a039da9af7f3dcff3845e06b"
        //     },
        //     {
        //         "partNum": 2,
        //         "partSha": "264fec4b9d62097e9d0b7c3ad64defb063c27497"
        //     },{...}
        // ]

        if (!await this.CheckInit() || !obj.svpShaSeqNumList)
            return

        if (this.m_addSvpShaSeqNumListToId == -1) {
            var request = this.db?.transaction([this.DBname], 'readwrite')
                .objectStore(this.DBname)
                .add(obj);
            let that = this
            if (request) {
                this.m_transactionLock = true
                request.onsuccess = function (event) {
                    let buff = that.db?.transaction([that.DBname]).objectStore(that.DBname).getAllKeys()
                    if (buff)
                        buff.onsuccess = function () {
                            if (buff && buff.result.length >= 1) {//这里就是吧刚刚写的id搞出来,因为这个id设置的是自增的
                                that.m_addSvpShaSeqNumListToId = buff.result[buff.result.length - 1] as number
                            }
                        }



                    that.m_transactionLock = false
                };

                request.onerror = function (event) {


                    that.m_transactionLock = false
                }
            }
        }
        else if (this.m_addSvpShaSeqNumListToId > 0) {
            let store = this.db?.transaction([this.DBname], 'readwrite').objectStore(this.DBname)
            let request = store?.get(this.m_addSvpShaSeqNumListToId)
            if (request) {
                request.onsuccess = function (event) {
                    // 获取我们想要更新的数据
                    var data = request?.result;
                    for (let item of obj.svpShaSeqNumList) {
                        // 更新你想修改的数据
                        data.svpShaSeqNumList.push(item);
                    }

                    // 把更新过的对象放回数据库
                    store?.put(data);

                };

            }
        }



    }
    public async addToDB(in_id: number = -1) {//rtNumber>=0时，意味着添加成功返回的是id
        if (!await this.CheckInit())
            return
        let buff_VideoData: VideoData | undefined
        buff_VideoData = this.m_videoData_phone

        if (!buff_VideoData)
            return


        let rtNumber = -2
        let store = this.db?.transaction([this.DBname], 'readwrite').objectStore(this.DBname)
        var request: IDBRequest<any> | undefined
        if (in_id >= 0) {
            request = store?.get(in_id)
            if (request) {
                let that = this
                request.onsuccess = function () {
                    // 获取我们想要更新的数据
                    var data = request?.result;
                    data.file = buff_VideoData?.file
                    // 把更新过的对象放回数据库
                    store?.put(data);
                    rtNumber = in_id

                };
                request.onerror = function (event) {
                    rtNumber = in_id
                    console.log('数据写入失败');
                    that.m_transactionLock = false
                }
                rtNumber = await new Promise(resolve => {
                    let buff = setInterval(() => {
                        if (rtNumber > -2) {
                            clearInterval(buff);
                            resolve(rtNumber);
                        }
                    }, 200);
                });
            }
        }
        else {
            request = store?.add(buff_VideoData.GetObj());
            let that = this

            if (request) {
                this.m_transactionLock = true
                request.onsuccess = function (event) {

                    let buff = that.db?.transaction([that.DBname]).objectStore(that.DBname).getAllKeys()
                    if (buff)
                        buff.onsuccess = function () {
                            rtNumber = -1

                            if (buff && buff.result.length >= 1) {//这里就是吧刚刚写的id搞出来
                                rtNumber = buff.result[buff.result.length - 1] as number
                                IndexDB.s_current_ids.push(rtNumber)
                            }

                        }

                    that.GetAllData().then((datas) => {
                        console.log('数据写入成功', datas, event, buff);

                    })


                    that.m_transactionLock = false
                };

                request.onerror = function (event) {
                    rtNumber = -1
                    console.log('数据写入失败');
                    that.m_transactionLock = false
                }
                rtNumber = await new Promise(resolve => {
                    let buff1 = setInterval(() => {
                        if (rtNumber > -2) {
                            clearInterval(buff1);
                            resolve(rtNumber);
                        }
                    }, 200);
                });
            }

        }

        return rtNumber

    }
    public async GetAllData() {

        let buff: VideoData[] = new Array<VideoData>()
        // console.log(1111, "allkey", this.db?.transaction([this.DBname]).objectStore(this.DBname).getAllKeys())
        await this.ForEach((cursor: any) => {
            // console.log("cursor,objectStore,id", cursor, objectStore)
            let username = IndexDB.s_username
            console.log(111, "GetAllData", cursor.value, username)
            if (cursor.value != undefined && cursor.value.player_id == username) {
                buff?.push(new VideoData(cursor.value))
            }


        })
        let that = this
        return new Promise(resolve => {
            let id = setInterval(() => {
                if (!that.m_transactionLock) {
                    clearInterval(id)
                    resolve(buff);
                }

            }, 100);
        }
        )
    }
    public Waite(times: number = 10) {
        let that = this
        let count = 0;
        return new Promise(resolve => {
            count++
            let id = setInterval(() => {

                if (that.m_initaled) {
                    clearInterval(id)
                    resolve(true);
                }
                if (count > times) {
                    clearInterval(id)
                    console.error("数据库初始化超时")
                    resolve(false);
                }

            }, 100);
        }
        )
    }
    public async CheckInit() {

        let counts = 0
        return await new Promise((resolve) => {
            let id = setInterval(() => {
                if (counts > 10) {
                    clearInterval(id)
                    if (!this.m_initaled) {
                        console.error("indexedDB未初始化")

                    }
                    if (this.m_transactionLock) {
                        console.error("有事务正在占用")

                    }
                    if (!this.m_videoData_phone) {
                        console.error("this.m_videoData为undefined")

                    }
                    resolve(false);
                }
                if (this.m_initaled && !this.m_transactionLock && this.m_videoData_phone) {
                    clearInterval(id)
                    resolve(true);
                }

            }, 200)
        });
    }

    public RemoveOutTimeFiles() {
        this.ForEach((cursor: { value: { date: string | number | Date; id: any; }; key: string; }, objectStore: { delete: (arg0: any) => void; }) => {
            // console.log("cursor,objectStore,id", cursor, objectStore)

            let file_date = new Date(cursor.value.date)
            let current_date = new Date()
            let diffHours = (current_date.getTime() - file_date.getTime()) / 1000 / 60 / 60
            if (typeof diffHours == "number" && diffHours > this.m_fileLastTimeOfHours) {
                objectStore.delete(cursor.value.id)
            }

        })
    }
    public async DeleteIndex(index: number) {
        if (!await this.CheckInit())
            return false

        let transaction = this.db?.transaction([this.DBname], "readwrite");
        let objectStore = transaction?.objectStore(this.DBname);//这个是事务，执行完后会回收
        if (objectStore) {
            if (objectStore.delete(index))
                return true

        }
        return false
    }
    public async ForEach(func: Function) {
        if (!await this.CheckInit())
            return
        let that = this
        let transaction = this.db?.transaction([this.DBname]);
        let objectStore = transaction?.objectStore(this.DBname);//这个是事务，执行完后会回收
        let finished = false
        // console.log(11, this.db, objectStore)
        if (objectStore) {
            that.m_transactionLock = true
            let my_cursor = objectStore.openCursor()
            my_cursor.onsuccess = function (event) {
                var cursor = my_cursor.result;

                if (cursor) {
                    func(cursor, objectStore)
                    cursor.continue();
                } else {
                    that.m_transactionLock = false
                }
                finished = true
            };
            my_cursor.onerror = function (event) {
                that.m_transactionLock = false
                finished = true
            }
        }
        let counts = 0
        return await new Promise((resolve) => {
            let id = setInterval(() => {
                if (counts > 10) {
                    clearInterval(id)

                    resolve(false);
                }
                if (finished) {
                    clearInterval(id)
                    resolve(true);
                }

            }, 200)
        });
    }
    public readDB() {
        this.ForEach((cursor: { value: { date: string | number | Date; id: any; }; key: string; }) => {
            let date = new Date(cursor.value.date)
            console.log('cursor ' + cursor.key, cursor.value, date);
        })

    }

} 
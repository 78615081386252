export * from './developmentConfig.js';
export * from './productionConfig.js';
export * from './types.js';

let isInternet = true;   // 这个开关控制数据上传的后端是测试服or正式服
let isDebug = false;      // 这个开关控制webappclient部署的是否是本地localhost
let ondataavailable_duration = 10000;//这个是录制视频每一个分片的长度
let live_key = "9890480c7d5c6583b78f14b9eebd7451"
export const setEnvConfig = function (Internet: boolean, Debug: boolean) {
    isInternet = Internet;
    isDebug = Debug;
}
export { isInternet, isDebug, ondataavailable_duration ,live_key};
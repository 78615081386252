export * from './core/uploadworker.js';
export * from './core/uploader.js';

export * from './types.js'
export function GetWorker() {
    return new Worker(new URL('./uploadWorker/uploadworker.js', import.meta.url));
}
export function GetWorker_HeatMap() {
    return new Worker(new URL('./uploadWorker/uploadworker_heatmap.js', import.meta.url));
}
export function GetWorker_Emotion() {
    return new Worker(new URL('./uploadWorker/uploadworker_emotion.js', import.meta.url));
}
export function GetWorker_KeyBoard() {
    return new Worker(new URL('./uploadWorker/uploadWorkerKeyboard.js', import.meta.url));
}
export function GetWorker_Mouse() {
    return new Worker(new URL('./uploadWorker/uploadworkerMouse.js', import.meta.url));
}

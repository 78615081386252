import { useImperativeHandle } from 'react';
import { forwardRef } from '../utils/with-display-name';
import Draggable from 'react-draggable';

export interface CameraViewProps {
  camewidth: number;
  cameheight: number;
  cameindex: number;
  streamSrcName: string;
}

export interface CameraViewRef {

}
const Draggable1: any = Draggable;
export const CameraDisplay = forwardRef<CameraViewRef>('CameraDisplay')(({
  camewidth, cameheight, cameindex, streamSrcName
}: CameraViewProps, ref) => {
  useImperativeHandle(ref, () => (
    {

    }
  ), []);

  return (
    <Draggable1 bounds="parent">
      <div id="myfacediv" style={{
        position: 'absolute',
        zIndex: 999,
        marginTop: '100px'
      }}>
        <video id={streamSrcName} autoPlay muted width={camewidth} height={cameheight} style={{
          position: 'absolute',
          zIndex: 999,
          top: '0px',
          left: '0px'
        }} ></video>
        <canvas id={`${streamSrcName}-emotion-canvas`} width={camewidth} height={cameheight} style={{
          position: 'absolute',
          zIndex: 999,
          top: '0px',
          left: '0px'
        }} />
      </div>
    </Draggable1>
  );
});


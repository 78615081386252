export enum UXLAB_PLUGIN_TYPE {
    web_android_video = "web_android_video",   // 网页版android手机视频
    web_camera_video0 = "web_camera_video_face",
    web_camera_video1 = "web_camera_video_hand",
    web_emotion = "web_emotion",
    web_android_heart = "web_android_heart",
    web_android_heatmap = "web_android_heatmap",
    web_PC_video = "web_PC_video",   //PC录屏
    web_keyboard = "web_keyboard",
    web_mouse = "web_mouse",
    flyflychen_test = "flyflychen_test",
}

//TODO: 按照数据组件进行拆分或者定义一个数据组件map
export class testData {
    public taskid: number = -1;
    public testid: number = -1;
    public reqid: number = -1;
    public username: string = "";
    public mysessionid: string = "";
    public plugins: UXLAB_PLUGIN_TYPE[] = [];
    public handVideo = false;
    public faceVideo = false;
    public androidVideo = false;
    public winVideo = false;
    public emotionData = false;
    public heatmapData = false;
    public startTime_ms: string = "";
    public endTime_ms: string = "";
    public isRecording: boolean = false;
    public maxSeqNum: number = 0;
    public seqNum: number = 0;  // svp分片的序号
    public svpUKey: string = "";
    public svpFileName: string = "";
    public svpVideoId: string = "";
    public svpShaList: Object[] = [];

    constructor(taskid: number, testid: number, reqid: number, username: string, mysessionid: string) {
        this.taskid = taskid;
        this.testid = testid;
        this.reqid = reqid;
        this.username = username;
        this.mysessionid = mysessionid;
    }
    public copy() {
        let buff = new testData(this.taskid, this.testid, this.reqid, this.username, this.mysessionid)
        buff.isRecording = this.isRecording
        buff.maxSeqNum = this.maxSeqNum
        buff.seqNum = this.seqNum
        buff.svpUKey = this.svpUKey
        buff.svpFileName = this.svpFileName
        buff.svpVideoId = this.svpVideoId
        buff.svpShaList = this.svpShaList
        return buff
    }
    public addPlugin(plugin: UXLAB_PLUGIN_TYPE) {
        this.plugins.push(plugin);
    }

    public dumps(): string {
        var content = this.plugins.map(function (plugin) { return ({ plugin: plugin, report: false, dataLength: -1 }); });
        return JSON.stringify({
            task: content,
            handVideo: this.handVideo,
            faceVideo: this.faceVideo,
            androidVideo: this.androidVideo,
            winVideo: this.winVideo,
            emotionData: this.emotionData,
            key: `${this.taskid}_${this.testid}_${this.username}`,
        })
    }

    public keys(): string {
        return `${this.taskid}_${this.testid}_${this.username}_${this.reqid}`;
    }

    public setIsRecording(isRecording: boolean) {
        this.isRecording = isRecording;
    }

    public checkIsRecording(): boolean {
        return this.isRecording;
    }

    public getSeqNum(): number {
        return this.seqNum;
    }

    public setSeqNum(seqNum: number) {
        this.seqNum = seqNum;
    }

    public setMaxSeqNum(maxSeqNum: number) {
        this.maxSeqNum = maxSeqNum;
    }

    public getMaxSeqNum(): number {
        return this.maxSeqNum;
    }
}
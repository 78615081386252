import { AbortController, AdbSubprocessProtocol, encodeUtf8, WritableStream, WritableStreamDefaultWriter, Adb } from "@yume-chan/adb";
//import { action } from "mobx";


import { uxlab } from "@yum/protobuf";

export class AdbCmd {
    public static packet = new uxlab.Heartbeats()


    private _socket: AdbSubprocessProtocol | undefined;
    private _writer: WritableStreamDefaultWriter<Uint8Array> | undefined
    public _isListing: boolean = false;
    private contro = new AbortController()
    private static adb: Adb | undefined = undefined
    public async spawnAndWaitLegacy(command: string | string[]) {
        await AdbCmd.adb?.subprocess.spawnAndWaitLegacy(command)
    }
    public static SetAdb(Adb: any) {
        AdbCmd.adb = Adb
    }
    public Reset() {
        this._socket = undefined;
        this._writer = undefined;
    }
    public async Init() {
        let shell = await AdbCmd.adb?.subprocess.shell()
        this._socket = shell;
        return this._socket


    }
    public isAborted() {
        return this.contro.signal.aborted
    }
    public abord() {
        this.contro.abort("close")
    }
    public async LogCat(cmd: string, func: ((arg0: Uint8Array) => void) | undefined = undefined) {
        cmd += "\r\r\n"

        if (!this._socket) {
            await this.Init()

            if (this._socket)
                (this._socket as AdbSubprocessProtocol).stdout.pipeTo(new WritableStream<Uint8Array>({
                    write: (chunk: Uint8Array) => {
                        if (func)
                            func(chunk)
                    },
                }), {
                    signal: this.contro.signal,
                });
        }
        if (!this._writer)
            this._writer = this._socket?.stdin.getWriter();

        this._writer?.write(encodeUtf8(cmd));
    }
    public static async Connect() {
        // await GlobalState.device?.reverse.add('tcp:3000', 'tcp:1234', socket => {
        //     runInAction(() => {
        //         console.log(111, "socket", socket)
        //     });
        //     socket.readable.pipeTo(new WritableStream({
        //         write: chunk => {
        //             runInAction(() => {
        //                 console.log(222, "chunk", chunk)
        //             });
        //         }
        //     }));

        //     // Return true to accept the connection.
        //     return true;
        // });
        const socket = new WebSocket('ws://localhost:8889');

        //设置连接的服务器
        socket.addEventListener('open', function (event) {
            console.log(111, event)
            socket.send("woyaoruazhi")
        });
        socket.addEventListener('message', function (event) {
            console.log('Message from server ', event);
        });
        socket.onerror = function (event) {
            console.error('onerror ', event);
        }

        socket.onclose = function (event) {
            console.error('onclose ', event);
        }


    }


}



// //键盘数据
// export function addKeyboard(array: keyEventData[]) {
//     for (var data of array) {
//         AdbCmd.packetKeyboard.keyEvent.push(
//             new keyboardData.uxlab.KeyEvent({
//                 relativeTime: data.relativeTime,
//                 action: data.action,
//                 value: data.value
//             })
//         )

//     }
// }

// //鼠标数据
// export function addMouse(array: mouseEventData[]) {
//     for (var data of array) {
//         AdbCmd.packetMouse.mouseEvent.push(
//             new mouseData.uxlab.MouseEvent({
//                 relativeTime: data.relativeTime,
//                 x: data.x,
//                 y: data.y,
//                 action: data.action
//             })
//         )

//     }
// }



export function Uint8ArrayToString(fileData: Uint8Array): any {
    var dataString = "";
    for (var i = 0; i < fileData.length; i++) {
        if (fileData[i]) {
            let buff: number = (fileData[i] as number)
            dataString += String.fromCharCode(buff);
        }

    }

    return dataString
}

export class productionConfig {
    isInternet = true;
    url = "https://gptlab.qq.com";
    apiBase = 'https://gptlab.qq.com';
    secret = '12345678';
    tapdLoginUrl = 'https://tapd.tencent.com/ptlogin/ptlogins/login?ref=https://gptlab.qq.com/&site=UXLab';
    websocketurl = "wss://gptlab.qq.com/api/v1/send_websocket/"
}

export const releaseConfig = new productionConfig();

import { isInternet } from "@yum/myconfig"
import { uxlab } from "@yum/protobuf";

import { AdbCmd, Uint8ArrayToString } from "./adb-cmd.js"
import { SaveForReUpload, ReUploadType } from "@fly/re-upload";

// import { uxlab } from "./heartpb.js"
export class heartbeat {
    constructor() {

    }
    private _saveForReUpload: SaveForReUpload | undefined = undefined
    private static s_minAppVersion = "1"
    private static g_globalDisplayStrs: any
    private last_heart_beat_length = -1
    private check_inter_num: any | undefined
    private packet = new uxlab.Heartbeats()
    private cmd = new AdbCmd()
    private checked = 0

    public static SetStrs(strs: any) {
        heartbeat.g_globalDisplayStrs = strs
    }
    public async Start() {
        this.last_heart_beat_length = -1;

        //这里需要等待两秒
        await this.cmd.LogCat("logcat -c")
        await this.cmd.spawnAndWaitLegacy(['am ', 'broadcast -a com.qm.heartrate.DataReceiver --es intent start --es taskid 1'])
        this.CheckHeart_onstart()
        await this.cmd.LogCat("logcat DataReceiver:D *:S")

    }
    public async Test() {//test在start之前。
        await this.cmd.Reset()
        let that = this
        this.checked = 0//这个东西用来检查心率设备是否在线,0代表没有检测到，-1表示异常，1代表正常
        function testLog(chunck: Uint8Array) {
            console.log("chunck:")
            let str = Uint8ArrayToString(chunck)
            console.log("chunck:", str)

            if (str.indexOf("DataReceiver") != -1 && str.indexOf("-V") != -1) {
                let buffs = str.split("DataReceiver:")
                let finals = buffs[1].split("\r\n")[0].split("|")
                let appVersion = finals[1]
                let appConnectedBlueteeth = finals[2]
                if (appVersion < heartbeat.s_minAppVersion) {
                    alert("心率appVersion过低，请升级至" + heartbeat.s_minAppVersion + "及以上")
                    that.checked = -1;
                }
                else if (appConnectedBlueteeth != "1") {
                    alert("心率app未连接至心率设备")
                    that.checked = -1;
                }
                else
                    that.checked = 1;
                console.log("app version", finals, that.checked)

            }
            else if (str.indexOf("DataReceiver") != -1 && str.indexOf("|") != -1) {
                that.checked = 1;
                let buffs = str.split("DataReceiver:")

                if (buffs.length == 2) {
                    that.checked = 1;
                    let nums = buffs[1].split("|")
                    let time = Number(nums[0])
                    let heart = Number(nums[1])
                    that.addHeartBeat(time, heart)
                }
            }
        }

        await this.cmd.LogCat("logcat -c \r\r\n logcat DataReceiver:D *:S", testLog);
        await this.cmd.spawnAndWaitLegacy(['am ', ' broadcast -a com.qm.heartrate.DataReceiver --es intent end'])
        await this.cmd.spawnAndWaitLegacy(['am ', ' broadcast -a com.qm.heartrate.DataReceiver --es intent test'])

        return await new Promise(async (resolve) => {
            let count = 0;
            while (true) {
                if (count > 6) {
                    alert("心率数响应超时")
                    resolve(false)
                    break
                }
                console.log("checked", this.checked)
                if (this.checked != 0)//如果检测到异常或者成功直接退出
                {
                    if (this.checked > 0)
                        resolve(true)
                    break

                }
                await new Promise(function (resolve1) {
                    setTimeout(resolve1, 500);
                });
                count++
            }
            if (this.checked > 0)
                resolve(true)
            else
                resolve(false)
        })

    }
    public CheckHeart_onstart() {
        var lenth = -1, count = 0;
        this.check_inter_num = setInterval(() => {
            if (count > 5)//连续5s没有更新数据
                alert(heartbeat.g_globalDisplayStrs.CheckHeart_onstart_error1)
            else
                count = 0
            let buff = this.packet.heatbeat
            if (buff[buff.length - 1]?.heartrate == 0)
                alert(heartbeat.g_globalDisplayStrs.CheckHeart_onstart_error1)
            if (buff.length == lenth)
                count++
            lenth = buff.length
            if (lenth > 2) {//暂时不启用,用来检测两次心率时间间隔
                if (this.packet.heatbeat[0]?.relativeTime != 0)
                    alert(heartbeat.g_globalDisplayStrs.CheckHeart_onstart_error2)
                // let last = buff[lenth - 1]
                // let before_last = buff[lenth - 2]

            }

        }, 1000)

    }
    public StopCheckHeart_onstart() {
        clearInterval(this.check_inter_num)
    }
    public CheckHeart_atend() {//检查心跳数据是否异常，比如说断连啥的
        if (this.packet.heatbeat.length == 0)
            return false
        else
            return true
    }
    public GetEncode() {
        return uxlab.Heartbeats.encode(this.packet).finish()
    }
    public static FromArrayToPB(inarray: Array<any>) {
        let packet = new uxlab.Heartbeats()
        for (const iterator of inarray) {
            packet.heatbeat.push(iterator)
        }
        return uxlab.Heartbeats.encode(packet).finish()
    }
    public Reset() {
        this.last_heart_beat_length = this.packet.heatbeat.length
        this.packet.heatbeat.splice(0, this.last_heart_beat_length);
    }
    public GetLastLenth() {
        return this.last_heart_beat_length
    }
    public async addHeartBeat(time: number, heart: number) {
        if (!this._saveForReUpload)
            this._saveForReUpload = new SaveForReUpload(ReUploadType.HEART_DATA)
        let obj = {
            /** Heartbeat relativeTime */
            relativeTime: time / 1000000,
            /** Heartbeat heartrate */
            heartrate: heart
        }
        await this._saveForReUpload.AddData(obj)
        this.packet.heatbeat.push(
            new uxlab.Heartbeat(obj)
        )
        if (!isInternet)
            console.log("addHeartBeat", this.packet)
    }
    public GetJsonHeartBeat() {
        return JSON.parse(JSON.stringify(this.packet.heatbeat))
    }
    public GetHeartBeatFromJson(json: Array<any>) {
        this.Reset()
        for (const iterator of json) {
            this.packet.heatbeat.push(new uxlab.Heartbeat(iterator))
        }
    }
}
import { Adb, AdbBackend, AdbPacketData } from '@yume-chan/adb';
import { action, makeAutoObservable, observable } from 'mobx';
import { testData } from '@yum/myconfig';
import CryptoJS from 'crypto-js';
import { uxlab } from '../../../../libraries/Protobuf/esm';
export type PacketLogItemDirection = 'in' | 'out';

export interface PacketLogItem extends AdbPacketData {
    direction: PacketLogItemDirection;

    commandString?: string;
    arg0String?: string;
    arg1String?: string;
    payloadString?: string;
}

const defaultTime = 86400000;
export class GlobalStateType {
    backend: AdbBackend | undefined = undefined;
    device: Adb | undefined = undefined;
    brand: string | undefined = undefined;
    resolution_w: number | undefined = undefined;
    resolution_h: number | undefined = undefined;

    errorDialogVisible = false;
    errorDialogMessage = '';
    // 默认是测试服
    isInternet = false;
    apiBase = 'https://test.uxlab.qq.com';
    secret = '12345678';
    LoggerApi = '';

    runTimeInterval = 60 * 60 * 5; // 游戏性测试的自动测试时间片长度（秒）
    startTestTimeInterval = 10; // 游戏性测试时间片之间的间隔（秒）
    scrcpyClickTimeInterval = 1; // 界面开始投屏和结束投屏的冷却时间
    scrcpyClickTime = Date.now() / 1000;

    logs: PacketLogItem[] = [];
    testRecordMap: Map<string, testData> = new Map(); // 保存手动创建的测试任务信息

    SupportedCameraList: object[] = [{ cameraName: 'USB CAMERA', brandInfo: 'PHILIPS P506' },
    { cameraName: 'HP 320 FHD Webcam', brandInfo: '320FHDWebcam' },
    { cameraName: 'HD Webcam eMeet C950', brandInfo: 'emeet 1080P' },
    { cameraName: 'USB2.0 PC CAMERA', brandInfo: 'lanseyaoji' }];

    constructor() {
        makeAutoObservable(this, {
            hideErrorDialog: action.bound,
            logs: observable.shallow,
        });
    }

    setDevice(backend: AdbBackend | undefined, device: Adb | undefined) {
        this.backend = backend;
        this.device = device;
        this.getBrand();
        this.getHeightWidth();
    }

    Uint8ArrayToString(fileData: Uint8Array): any {
        let dataString = '';
        for (let i = 0; i < fileData.length; i++) {
            if (fileData[i]) {
                let buff: number = (fileData[i] as number);
                dataString += String.fromCharCode(buff);
            }
        }

        return dataString;
    }

    getBrand = () => {
        GlobalState.device?.getProp('ro.product.brand').then((res) => {
            this.brand = res;
        });
    };

    getHeightWidth = () => {
        this.device?.subprocess.shell('wm size').then(action(
            (shell: any) => {
                shell.stdout.pipeTo(new WritableStream<Uint8Array>(
                    {
                        write: (chunk: Uint8Array) => {
                            let resolutionstr = this.Uint8ArrayToString(chunk);
                            let resolution: string[] = resolutionstr.split(':')[1]?.trim().split('x');
                            this.resolution_w = resolution ? parseInt(resolution[0]) : -1;
                            this.resolution_h = resolution ? parseInt(resolution[1]) : -1;
                        }
                    }));
            }
        ));
    };

    showErrorDialog(message: Error | string) {
        if (this.errorDialogVisible)
            return
        this.errorDialogVisible = true;
        if (message instanceof Error) {
            this.errorDialogMessage = message.stack || message.message;
        } else {
            this.errorDialogMessage = message;
        }
        try {
            window.aegisEntry.error(message);
        } catch (e) { }
    }

    hideErrorDialog() {
        this.errorDialogVisible = false;
    }

    appendLog(direction: PacketLogItemDirection, packet: AdbPacketData) {
        (packet as PacketLogItem).direction = direction;
        this.logs.push((packet as PacketLogItem));
        try {
            window.aegisEntry.error(packet);
        } catch (e) { }
    }

    clearLog() {
        this.logs = [];
    }

    encryptDES(data: string, key: string) {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const encrypted = CryptoJS.DES.encrypt(data, keyHex, {
            iv: CryptoJS.enc.Utf8.parse(key),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        const hexStr = encrypted.ciphertext.toString().toUpperCase();

        console.log(`hexStr->${hexStr}`);
        const oldHexStr = CryptoJS.enc.Hex.parse(hexStr);
        console.log(`oldHexStr->${oldHexStr}`);
        // 将密文转为Base64的字符串
        const base64Str = CryptoJS.enc.Base64.stringify(oldHexStr);
        console.log(`base64Str->${base64Str}`);
        return oldHexStr;
    }
}

class GlobalDisplayStrs {
    website_title: string = 'gptlab';
    connect_onerror1: string = '连接失败，请重新拔插usb设备';
    connect_onsuccess: string = '连接成功';
    CheckHeart_onstart_error1: string = '请检查心率设备';
    CheckHeart_onstart_error2: string = '心率初始化数据不为0';
    stop_recorder_error1: string = '心率数据异常或者上传信息异常';

}
/**
 * 用户登录信息
 */
class userInfo {
    username: string = '';
    password: string = '';
    LoginState: boolean = false;
    loginType: number = 0;
    mysessionid?: string;

    setUserInfo(username: string, LoginState: boolean, loginType: number, mysessionid: string) {
        this.username = username;
        this.LoginState = LoginState;
        this.loginType = loginType;
        this.mysessionid = mysessionid;
    }


}

export const state = new userInfo();
export const GlobalState = new GlobalStateType();
export const g_globalDisplayStrs = new GlobalDisplayStrs();

